import { CancelToken } from 'axios';
import { AppUser } from '~/models/User/AppUser';
import { AutoLoginAppUserResponse } from '~/models/User/AutoLoginAppUserResponse';
import { CheckUsernameResponse } from '~/models/User/CheckUsername';
import { CreateAppUserResponse, UpsertAppUserRequest } from '~/models/User/CreateAppUser';
import { ResendEmailResponse } from '~/models/User/ResendEmailResponse';
import { AppUserSwitchRoleRequest, AppUserSwitchRoleResponse } from '~/models/User/SwitchRoleAppUser';
import { VerifyAppUserRequest, VerifyAppUserResponse } from '~/models/User/VerifyAppUser';
import { addAddress } from './addresses/add-address';
import { listAddresses } from './addresses/list-addresses';
import { removeAddress } from './addresses/remove-address';
import { syncStates } from './addresses/sync-states';
import { syncStreets } from './addresses/sync-streets';
import { syncSuburbs } from './addresses/sync-suburbs';
import { changeContactPreference } from './change-contact-preference';
import { getProfile } from './get-profile';
import { Api } from '../api';
import { applyRecaptcha } from '@infotrack/infotrackgo.web.core/framework';
import { Settings } from '~/framework/setting';


export const AccountsApi = (() => {
    const baseUrl = 'accounts/users';

    const create = (createAppUserRequest: UpsertAppUserRequest, cancelToken?: CancelToken) => {
        return applyRecaptcha(
            Settings.settings.GoogleRecaptchaSiteKey,
            (recaptcha: string) => Api.post<CreateAppUserResponse>(
                baseUrl,
                createAppUserRequest,
                { cancelToken, headers: { recaptcha } }
            )
        );
    }

    const switchUserRole = (userId: number, switchUserRoleRequest: AppUserSwitchRoleRequest, cancelToken?: CancelToken) => {
        return applyRecaptcha(
            Settings.settings.GoogleRecaptchaSiteKey,
            (recaptcha: string) => Api.post<AppUserSwitchRoleResponse>(
                `${baseUrl}/${userId}/switch-role`,
                switchUserRoleRequest,
                { cancelToken, headers: { recaptcha } }
            )
        );
    }

    return {
        getMe: (cancelToken?: CancelToken) => Api.get<AppUser>(`${baseUrl}/me`, { cancelToken }),
        create,
        // Sync itk user into itg.
        sync: (cancelToken?: CancelToken) => Api.post<void>(`${baseUrl}/sync`, {}, { cancelToken }),
        update: (updateAppUserRequest: AppUser, cancelToken?: CancelToken) => Api.put<AppUser>(baseUrl, updateAppUserRequest, { cancelToken }),
        // Exchange the User's IDP-ApiKey for its credentials (including access token).
        exchangeApiKey: (userId: number, cancelToken?: CancelToken) => Api.post<AutoLoginAppUserResponse>(`${baseUrl}/${userId}/exchange-apikey`, userId, {cancelToken}),
        verify: (veryfyAppUserRequest: VerifyAppUserRequest, cancelToken?: CancelToken) => Api.post<VerifyAppUserResponse>(`${baseUrl}/${veryfyAppUserRequest.userId}/verify`, veryfyAppUserRequest, { cancelToken }),
        resendEmail: (cancelToken?: CancelToken) => Api.post<ResendEmailResponse>(`${baseUrl}/resend-email`, { cancelToken }),
        checkUsername: (email: string, cancelToken?: CancelToken) => Api.get<CheckUsernameResponse>(`${baseUrl}/check-username`, { params: { userEmail: email }, cancelToken }),
        switchUserRole,
        changeContactPreference,
        // Profile.
        getProfile,
        // Addresses.
        addresses: {
            addAddress,
            listAddresses,
            removeAddress,
            syncStates,
            syncSuburbs,
            syncStreets
        }
    }
})();
