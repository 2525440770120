import { makeAutoObservable, runInAction } from 'mobx';
import { localStorageFormManager } from '@infotrack/infotrackgo.web.core/framework/utils/localStorageFormManager';
import { FormBase } from '@infotrack/infotrackgo.web.core/models';

export class StandardFormStore<TForm> implements FormBase<TForm> {
    public isValid: boolean = false;
    public formValues: TForm | null = null;
    public errors: any | null = null;
    public localStorageKey: string;
    public formikRestterFunc: () => void;

    constructor(lsKey: string) {
        if (typeof window !== 'undefined') {
            this.localStorageKey = lsKey;

            const lsForm = this.getFormValuesFromLocalStorage(lsKey);
            this.isValid = lsForm.isValid;
            this.formValues = lsForm.formValues ?? null;
            this.errors = lsForm.errors ?? null;
        }
        makeAutoObservable(this);
    }

    public setFormikResetterFunc(func: () => void) {
        this.formikRestterFunc = func;
    }

    // If any formValues are present, will return the errors else
    // will return null. this allows us to guage wether or not the form has been touched.
    public getIsTouched() {
        if (!this.formValues) return null;
        const entries = Object.entries(this.formValues)
        for (let i = 0; i < entries.length; i++) {
            const [key, value] = entries[i];
            if (
                typeof (value) !== 'object'
                && typeof (value) !== 'function'
            ) {
                // If we have a primitive value
                if (value) return this.errors;
            }
        }
        return null;
    }

    // More efficiently combines setFormValues, isValid, errors e.t.c
    public sync(isValid: boolean, values: TForm | null, errors: any, formValuesToExclude?: string[]) {
        runInAction(() => {
            this.formValues = values;
            this.isValid = isValid;
            this.errors = errors;
            this.syncOnLocalStorage(formValuesToExclude);
        });
    }

    public setFormValues = (formValues: TForm | null, formValuesToExclude?: string[]) => {
        this.formValues = formValues;
        this.syncOnLocalStorage(formValuesToExclude);
    }

    public setIsValid = (isValid: boolean, formValuesToExclude?: string[]) => {
        this.isValid = isValid;
        this.syncOnLocalStorage(formValuesToExclude);
    }

    public setErrors = (errors: any, formValuesToExclude?: string[]) => {
        this.errors = errors;
        this.syncOnLocalStorage(formValuesToExclude);
    }

    public resetForm = () => {
        this.resetOnLocalStorage();
        if (typeof this.formikRestterFunc === 'function') this.formikRestterFunc();
        runInAction(() => {
            this.formValues = null;
            this.isValid = false;
            this.errors = null;
        });
    }

    public resetOnLocalStorage = () => {
        localStorageFormManager<TForm>(this.localStorageKey).reset();
    }

    // Note: formValuesToExclude represents any set of fields that we should not keep on local storage for safety issues.
    // e.g. user password
    private syncOnLocalStorage = (formValuesToExclude?: string[]) => {
        const formValuesCopy = { ...(this.formValues ?? {}) } as TForm | null;
        if (formValuesCopy && formValuesToExclude) {
            formValuesToExclude.forEach((property) => {
                formValuesCopy[property] = undefined;
            });
        }
        localStorageFormManager<TForm>(this.localStorageKey).set(this.isValid, formValuesCopy, this.errors);
    }

    private getFormValuesFromLocalStorage = (lsKey: string): FormBase<TForm> => {
        const localStorageValue = localStorageFormManager<TForm>(lsKey).get();
        if (localStorageValue) return localStorageValue;
        return { isValid: false, formValues: null, errors: null };
    }
}