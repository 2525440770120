import { ComponentsOverrides, ComponentsProps, ComponentsVariants } from '@mui/material';
import { Theme } from '@mui/system';
import { muiColoursTheme } from './muiColoursTheme';

// Default - variant="text" and color="primary"
// font related defaults are set on muiTypographyTheme
// We overwritte styles from palette in here (primary, secondary, etc...)
export const muiButtonTheme: {
    defaultProps?: ComponentsProps['MuiButton'];
    styleOverrides?: ComponentsOverrides<Theme>['MuiButton'];
} = {
    styleOverrides: {
        // Default properties for all buttons
        root: {
            textTransform: 'none',
            // Buttons have a standard border radius of 0.4rem.
            borderRadius:  '0.4rem',
            boxShadow: 'none !important'
        },
        // Styles for our "TEXT" variant (DEFAULT)
        textPrimary: () => ({
            backgroundColor: 'transparent',
            color: muiColoursTheme.ITG_BLUE,
            '&:hover': {
                backgroundColor: 'transparent',
                color: muiColoursTheme.ITG_GREY__DARK
            }
        }),
        textSecondary: () => ({
            backgroundColor: 'transparent',
            color: muiColoursTheme.ITG_GREY__DARK,
            '&:hover': {
                backgroundColor: 'transparent',
                color: muiColoursTheme.ITG_BLACK
            }
        }),
        // Styles for our "CONTAINED" variant
        containedPrimary: () => ({
            background: muiColoursTheme.ITG_ORANGE,
            color: muiColoursTheme.ITG_WHITE,
            boxShadow: 'none',
            '&:hover': {
                backgroundColor: muiColoursTheme.ITG_ORANGE__LIGHT,
                color: muiColoursTheme.ITG_WHITE
            }
        }),
        containedSecondary: () => ({
            backgroundColor: muiColoursTheme.ITG_BLUE,
            color: muiColoursTheme.ITG_WHITE,
            boxShadow: 'none',
            '&:hover': {
                backgroundColor: muiColoursTheme.ITG_BLUE__LIGHT,
                color: muiColoursTheme.ITG_WHITE
            }
        }),
        // Styles for our "OUTLINED" variant
        outlinedPrimary: () => ({
            borderColor: muiColoursTheme.ITG_ORANGE,
            color: muiColoursTheme.ITG_ORANGE,
            '&:hover': {
                backgroundColor: muiColoursTheme.ITG_BLUE,
                color: muiColoursTheme.ITG_WHITE
            }
        }),
        // This style is reserved for the muted style buttons with a flat light blue background
        // and grey text.
        outlinedSecondary: () => ({
            backgroundColor: muiColoursTheme.ITG_BLUE__MUTED,
            color: muiColoursTheme.ITG_GREY__DARK,
            border: 'none',
            '&:hover': {
                backgroundColor: muiColoursTheme.ITG_GREY__LIGHT,
                border: 'none',
            }
        })
    },
};