import { incrementValueInMapByOne } from '@infotrack/infotrackgo.web.core/framework/utils/incrementValueInMap';
import { ServiceToOrder } from '@infotrack/infotrackgo.web.core/models';
import { Discount } from '~/models/Responses/Discounts/Discount';
import { DiscountCodeApplicator } from './discountCodeApplicator';

// Check if a discount record is satisfied by the current cart.
export const discountConditionIsSatisfied = (discount: Discount, cartItems: ServiceToOrder[], servicesMap: Map<string, number>) => {
    const discountHasApplicationLimit = !!discount.applyToInstances;

    // Look for the next cart item that satisfies this condition.
    // As a side effect, if the find returns a cart item
    const foundConditionSatisifed = cartItems.find(ct => {
        // If this cart item has already been claimed, skip...
        if (servicesMap.get(ct.uuid)) return;

        let satisfied = DiscountCodeApplicator(discount).check(ct);

        // Check application limit. Return satisifed if there is NO application limit.
        if (!discountHasApplicationLimit) return satisfied;

        // If there is an discount application limit (There will be most of the time).
        // Ensure that this condition is also satisifed.
        return (
            satisfied
            && ((servicesMap.get(ct.uuid) ?? 0) < (discount.applyToInstances || 0))
        );
    });

    // If a cart item was found that satisifies the condition,
    // then record that it did in fact satisfy the condition...
    if (foundConditionSatisifed) incrementValueInMapByOne(foundConditionSatisifed.uuid, servicesMap);
    return !!foundConditionSatisifed;
};