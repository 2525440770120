import { CancelToken } from 'axios';
import { Api } from '~/api/api';
import { invalidatesUserProfileCache } from '~/framework/accounts/invalidateUserProfileCache';
import { ContactPreferenceRequest } from '~/models/User/ContactPreferenceRequest';

// Change a user's contact preference, invalidating the profile cache.
export const changeContactPreference = (
    request: ContactPreferenceRequest,
    cancelToken?: CancelToken
): Promise<void> => {
    return invalidatesUserProfileCache(() => (
        Api.put<void>(`accounts/users/contact-preference`, request, { cancelToken })
    ));
};
