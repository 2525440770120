import { getMeAndSyncWithStore } from "../accounts/getMeAndSyncWithStore";
import { getProfileAndSyncWithStore } from "../accounts/getProfileAndSyncWithStore";
import { CancelToken } from "axios";
import { Auth } from "../auth/auth";
import { UserStore } from "~/stores/user/userStore";
import { logger } from "@infotrack/infotrackgo.web.core/framework";

/**
 * Consolidates all sync for user operations
 */
export const userOnStartup = async (userStore: UserStore, cancelToken?: CancelToken) => {
    if (typeof window === 'undefined') return;

    try {
        // If user is not logged in, only get cart.
        if (!Auth.isAuthenticated()) {
            await userStore.cartStore.getOrSetCart();
            return;
        }

        // Else, load everything.
        await getMeAndSyncWithStore(userStore, cancelToken);
        await getProfileAndSyncWithStore(userStore, cancelToken);
        await userStore.cartStore.getOrSetCart();
    } catch (err: any) {
        logger.error('Failed to load user', { error: err });
    }
}