import { Alert, AlertTitle, IconButton, Snackbar, SnackbarCloseReason, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react';
import { useWindowSize } from '@infotrack/infotrackgo.web.core/framework/hooks/useWindowSize';
import { SnackbarSettingOptions, SnackbarSettings } from '~/components/MuiSnackbar/types/SnackbarSettingOptions';
import { observer } from 'mobx-react-lite';
import { useStore } from '~/stores/stores';

// Styles
import styles from './mui-snackbar.module.scss';

const SnackbarDefaultSetting: SnackbarSettingOptions = {
    mobile: {
        variant: 'standard',
        anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom'
        }
    },
    desktop: {
        variant: 'outlined',
        anchorOrigin: {
            horizontal: 'right',
            vertical: 'top'
        }
    }
};

namespace MuiSnackbarComponents {
    export function SnackBarCloseIcon({ closeSnackbar }: { closeSnackbar: (event: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => void }) {
        return (
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={closeSnackbar}
                className={styles.itgSnackbarCloseBtn}
            >
                <CloseIcon fontSize="small"/>
            </IconButton>
        );
    }

    export function SnackBarContent(props: { title?: string, message: string, action: any }) {
        const { title, message, action } = props;
        return (
            <>
                {title &&
                    (
                        <AlertTitle className={styles.itgSnackbarAlertTitle}>
                            <Typography variant="body2">
                                {title}
                            </Typography>
                        </AlertTitle>
                    )
                }
                <Typography variant="body1">
                    {message}
                </Typography>
                <br/>
                {action}
            </>
        );
    }
}

export const MuiSnackbar = observer(function MuiSnackbar() {
    const windowSize = useWindowSize();
    const [settings, setSettings] = useState<SnackbarSettings>(SnackbarDefaultSetting.desktop);
    const { closeSnackbar, open, snackbar: { message, title, action, autoHideDuration, severity, customContent } } = useStore().snackbarStore;

    const isMobile = (): boolean => {
        return Boolean(windowSize?.width && windowSize.width <= 600);
    };

    useEffect(() => {
        if (isMobile()) setSettings(SnackbarDefaultSetting.mobile);
        else setSettings(SnackbarDefaultSetting.desktop);
    }, [windowSize]);

    return (
        <Snackbar
            open={open}
            autoHideDuration={autoHideDuration ? autoHideDuration : 3000}
            onClose={() => closeSnackbar()}
            anchorOrigin={{ vertical: settings.anchorOrigin.vertical, horizontal: settings.anchorOrigin.horizontal}}
            classes={{root: styles.itgSnackbar}}
        >
            {
                customContent ?
                customContent :
                (
                    <Alert
                        onClose={() => closeSnackbar()}
                        className={!isMobile() ? styles.itgSnackbarAlert : ''}
                        severity={severity ?? 'success'}
                        variant={settings.variant}
                        sx={{ width: '100%' }}
                        action={<MuiSnackbarComponents.SnackBarCloseIcon closeSnackbar={() => closeSnackbar()} />}
                    >
                        <MuiSnackbarComponents.SnackBarContent
                            title={title}
                            action={action}
                            message={message}
                        />
                    </Alert>
                )
            }
        </Snackbar>
    );
});
