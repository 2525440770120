import { ComponentsOverrides, ComponentsProps, Palette, Theme } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { muiColoursTheme } from './muiColoursTheme';

export const muiTypographyTheme: TypographyOptions | ((palette: Palette) => TypographyOptions) = {
    fontFamily: [
        'Lato',
        'Arial'
        ].join(','),
    // here we add our default font-size the same as most browsers, 16px (1rem)
    fontSize: 16,
    fontWeightLight: 100,
    fontWeightMedium: 400, // default
    fontWeightRegular: 400,
    fontWeightBold: 700,
    // Note: Add all font-sizes in rem.
    h1: {
        fontSize: '4rem',   // 64px
        lineHeight: 1.67,
        letterSpacing: '0.01em',
        fontWeight: 400,
    },
    h2: {
        fontSize: '3.5rem', // 56px
        fontWeight: 400,
    },
    h3: {
        fontSize: '3rem',   // 48px
        fontWeight: 400,
    },
    h4: {
        fontSize: '2rem',   // 32px
        fontWeight: 400,
        marginBottom: '0.5rem'
    },
    h5: {
        fontSize: '1.5rem', // 24px
        fontWeight: 400,
        marginBottom: '0.5rem'
    },
    h6: {
        fontSize: '1.2rem', // 19.2px
        fontWeight: 400,
    },
    body1: {
        fontSize: '1rem', // 16px
        fontWeight: 400,
        color: muiColoursTheme.ITG_BLACK
    },
    body2: {
        fontSize: '1rem', // 16px
        fontWeight: 700,
        color: muiColoursTheme.ITG_BLACK
    },
    subtitle1: {
        fontSize: '1.1rem', // 17.6px
        fontWeight: 700,
        color: muiColoursTheme.ITG_BLACK
    },
    subtitle2: {
        fontSize: '1.1rem', // 17.6px
        fontWeight: 400,
        color: muiColoursTheme.ITG_BLACK
    },
    caption: {
        fontSize: '0.8rem', // 12.8px
        fontWeight: 400,
        color: muiColoursTheme.ITG_BLACK
    },
    button: {
        fontWeight: 400
    },
};

export const muiTypographyComponent: {
    defaultProps?: ComponentsProps['MuiTypography'];
    styleOverrides?: ComponentsOverrides<Theme>['MuiTypography'];
} = {
    defaultProps: {
        variantMapping: {
            subtitle1: 'span',
            subtitle2: 'span',
            caption: 'span'
        }
    }
};