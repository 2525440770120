import { getLocalStorage } from '@infotrack/infotrackgo.web.core/framework';
import { Constants } from '../constants/constants';

// Invalidate the profile cache so that the app knows it must refetch it
// when getProfileSyncStore is called next (on next app refresh).
export const invalidatesUserProfileCache = <TResponse> (apiCallback: () => Promise<TResponse>) => {
    return apiCallback()
        .then(res => {
            getLocalStorage()?.setItem(Constants.USER.PROFILE_CACHE_INVALID_KEY, 'true');
            return res;
        });
};