import { RumInitConfiguration, datadogRum } from '@datadog/browser-rum';
import { logger } from '@infotrack/infotrackgo.web.core/framework';

/**
 * Initialise the datadog REAL USER MONITORING object with the provided configuration.
 */
export const initialiseRum = (rumCongig: RumInitConfiguration) => {
    try {
        datadogRum.init({
            ...rumCongig,
            sessionSampleRate: 5,
            sessionReplaySampleRate: 1,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            trackSessionAcrossSubdomains: true,
            defaultPrivacyLevel: 'mask-user-input',
        });
    } catch (error: any) {
        logger.error('Error initialising datadogRum', { error });
    }
};