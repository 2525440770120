import { makeAutoObservable } from 'mobx';
import { ITrackingInformation, TrackingInformation } from '~/models/App/TrackingInformation';
import { SearchStore } from '~/stores/search/searchStore';
import { InitialAppState } from '~/stores/stores';
import { UserStore } from '~/stores/user/userStore';
import { DimensionsStore } from '~/stores/util/utilStore';
import { SnackbarStore } from './snackbar/snackbarStore';
import { FormsStore } from './forms/formsStore';

export class AppStore {
    public userStore = new UserStore();

    public dimensionsStore = new DimensionsStore();

    public snackbarStore = new SnackbarStore();

    public formsStore = new FormsStore();

    public trackingInformation: ITrackingInformation = {};

    constructor(state: InitialAppState) {
        makeAutoObservable(this);
        this.setTrackingInformation(state.trackingInformation);
    }

    public setTrackingInformation = (trackingInformation: TrackingInformation) => {
        this.trackingInformation = trackingInformation;
    };
}

export const searchStore = new SearchStore();
