
import { Address } from '@infotrack/infotrackgo.web.core/models';
import { CancelToken } from 'axios';
import { Api } from '~/api/api';
import { IUserProfile } from '~/models/User/UserProfile';

// Sync the interested suburbs for user...
export const getProfile = (
    cancelToken?: CancelToken
) => {
    return Api.get<IUserProfile>(`accounts/users/profile`, { cancelToken })
        .then((data) => ({
            ...data,
            addresses: data.addresses.map(add => new Address(add))
        }  as IUserProfile));
};