import { CancelToken } from 'axios';
import { getProfile } from '~/api/accounts/get-profile';
import { Timings } from '@infotrack/infotrackgo.web.core/enums/Timings';
import { UserStore } from '~/stores/user/userStore';
import { Constants } from '../constants/constants';
import { applyCache, getLocalStorage } from '@infotrack/infotrackgo.web.core/framework';
import { Address } from '@infotrack/infotrackgo.web.core/models';

// Get the user profile and sync it with the user store.
// takes into account wether or not the current profile cache is valid.
export const getProfileAndSyncWithStore = (
    userStore: UserStore,
    cancellationToken?: CancelToken
) => {
    const userLoginId = userStore.userInformation?.userLoginId || 0;
    const ls = getLocalStorage();
    // Do not read from cache if the profile cache has been invalidated (it was mutated).
    const saveOnly = Boolean(ls?.getItem(Constants.USER.PROFILE_CACHE_INVALID_KEY));
    // Set the loading flags...
    userStore.updateProfileHasLoadedFromLocalStorage();
    userStore.setProfileIsLoading(true);
    return applyCache(
        () => getProfile(cancellationToken).then(res => ({ data: res })),
        { requestName: 'get-profile', saveOnly, expireAfter: Timings.TenMinutes, vars: { userLoginId } }
    )
        .then(res => {
            const addresses = res.data.addresses.map(add => new Address(add));
            res.data.addresses = addresses;
            userStore.setUserProfile(res.data);
            ls?.removeItem(Constants.USER.PROFILE_CACHE_INVALID_KEY);
        })
        .finally(() => {
            userStore.setProfileIsLoading(false);
            userStore.setProfileHasLoaded(true);
        });
};
