import { PaymentTypes } from '@infotrack/infotrackgo.web.core/enums/PaymentTypes';
import { loadInstanceVariables } from '@infotrack/infotrackgo.web.core/framework/utils/loadInstanceVariables';
import { HostedFieldsTokenizePayload } from './HostedFields/HostedFieldsTokenizePayload';
import { RequestPaymentPayload } from '../RequestPaymentPayload';
import { ThreeDSecureVerifyPayload } from './ThreeDSecure/ThreeDSecureVerifyPayload';

export namespace Payment.Braintree {
    // A supporting object that allows us to track what the current state of the payment
    // UI is in the checkout store. it also provides the tokeniser func so that we may retrieve the
    // payment nonce.
    export class BraintreeState {
        /**
         * Is Payment valid
         */
        public isValid = false;

        /**
         * Tokeniser for the respective payment type. CreditCard | PayPal | GooglePay | ApplePay
         */
        public tokeniser?: (() => Promise<HostedFieldsTokenizePayload | RequestPaymentPayload>) = undefined;

        /**
         * 3DSecure custom tokeniser for CreditCards
         */
        public verifyCardWithThreeDSecure?: ((payload: HostedFieldsTokenizePayload) => Promise<ThreeDSecureVerifyPayload>) = undefined;

        /**
         * PaymentMethod
         */
        public paymentMethod: PaymentTypes = PaymentTypes.Unknown;

        constructor(opts?: Partial<BraintreeState>) {
            loadInstanceVariables(this, opts);
        }
    }
}