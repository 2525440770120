import { User , UserManager, UserManagerSettings, WebStorageStateStore} from 'oidc-client-ts';
import Cookie from 'js-cookie';
import { Settings } from '../setting';
import { AppUserTokenResponse, GetOidcUserProfile } from '~/models/User/AppUserTokenResponse';
import { Constants, getLocalStorage, PersistentTimeout } from '@infotrack/infotrackgo.web.core/framework';
import { logger } from '@infotrack/infotrackgo.web.core/framework/logging/logger';
import { parseJwt } from '@infotrack/infotrackgo.web.core/framework/utils/parseJwt';
import { AuthApi } from '@infotrack/infotrackgo.web.core/api';
import Router, { NextRouter } from 'next/router';
import { CancelToken } from 'axios';
import { ConnectTokenRequest } from '@infotrack/infotrackgo.web.core/models';
import { Constants as AppConstants } from '~/framework/constants/constants';
import { refreshLoggingContext } from '../constants/loggingContexts';

const accessTokenCookie = 'access_token';
const refreshTokenCookie = 'refresh_token';
const preLoginLocation = 'pre-login-location';
const daysInTenYears = 3650;

export const Auth = (() => {
    const refreshTimeout = PersistentTimeout('refresh_timer', () => tryRefresh('expiring'));
    refreshTimeout.restore();

    const getUserManager = (extraQueryParams: any = {}) => {
        const settings = getSettings();
        settings.extraQueryParams = extraQueryParams ?? {};
        return new UserManager(getSettings());
    }

    const getSettings = (): UserManagerSettings => {
        const settings: any = Settings.settings.IdentityConfig;
        settings.userStore = new WebStorageStateStore({ store: window.localStorage })
        return settings;
    }

    /**
     * Auto log a user in using their token, this is common place for
     * a sign up action.
     */
    const autoLogin = async (user: AppUserTokenResponse) => {
        let settings: any = {
            access_token: user.access_token,
            id_token: '',
            session_state: '',
            refresh_token: user.refresh_token,
            token_type: user.token_type,
            scope: user.scope,
            profile: user.oidcUserProfile,
            expires_at: user.oidcUserProfile.exp,
            state: undefined
        };
        const newOidcUser = new User(settings);
        const oidc = getUserManager();
        oidc.storeUser(newOidcUser);
        handleNewUser(newOidcUser);
        return await me();
    }

    /**
     * Redirects the user to the login flow
     */
    const login = (returnUrl?: string, extraQueryParams?: any) => {
        if (returnUrl) Cookie.set(preLoginLocation, returnUrl);
        const oidc = getUserManager(extraQueryParams);
        oidc.signinRedirect({
            state: { returnUrl }
        });
    };

    /**
     * Handles post-auth
     */
    const postLogin = async (router: NextRouter, onComplete: () => Promise<void>) => {
        try {
            // If the guest cookie is present, let's purge it.
            clearIsGuest();
            const oidc = getUserManager();
            const user = await oidc.signinRedirectCallback();
            // User has logged in, purge the IS_GUEST cookie.
            handleNewUser(user);

            await onComplete();
            router.push(Cookie.get(preLoginLocation) || '/');
        } catch (e: any) {
            logger.error('Post login failed', e);
            window.location.href = '/'
        }
    };

    // Store a new user and listen on events...
    const handleNewUser = (user: User) => {
        setAccessToken(user.access_token);
        if (getIsGuest()) return;
        if (!user.refresh_token) return;

        setRefreshToken(user.refresh_token ?? '');
        const expiresInSeconds = user.expires_in || 0;
        logger.info('Setting refresh timer', { ...refreshLoggingContext, expires_in: user.expires_in });
        if (expiresInSeconds) {
            // We check 10 seconds before so the user never ends up with invalid state.
            const expiringNotificationSeconds = 10;
            let expiringInSeconds = expiresInSeconds - expiringNotificationSeconds;
            if (expiringInSeconds < 0) expiringInSeconds = 0;

            // Invoke the check. This will always pre-emptively check login state of user
            // and refresh if necessary.
            refreshTimeout.clear();
            refreshTimeout.start(expiringInSeconds * 1000);

            logger.info('Refresh timer set', { ...refreshLoggingContext, expiringInSeconds });
        } else {
            logger.info('Expiries in was falsy, no refresh will be attempted', refreshLoggingContext);
        }
    }

    const tryRefresh = async (eventName: string) => {
        if (getIsGuest()) {
            logger.info('User is a guest, skipping refresh', refreshLoggingContext);
            return getAccessToken();
        }
        if (!getRefreshToken()) {
            logger.info('Refresh triggered with no refresh token, skipping...', refreshLoggingContext);
            return getAccessToken();
        }
        try {
            logger.info(`Access token ${eventName}, refreshing...`, refreshLoggingContext);
            const refreshRes = await refresh();
            // If the refresh res returned a new refresh token, replace the current one (which is likely invalid).
            if (refreshRes.refresh_token) setRefreshToken(refreshRes.refresh_token);

            const profile = GetOidcUserProfile(refreshRes.access_token);
            const refreshedUser = new User({
                access_token: refreshRes.access_token,
                id_token: '',
                session_state: '',
                refresh_token: refreshRes.refresh_token,
                token_type: 'Bearer',
                scope: refreshRes.scope,
                profile: profile,
                expires_at: profile.exp
            });

            const newOidc = getUserManager();
            newOidc.storeUser(refreshedUser);
            handleNewUser(refreshedUser);
            return refreshRes.access_token;
        } catch (error: any) {
            logger.error('Refreshing token failed, logging out...', { error, ...refreshLoggingContext });
            // This has failed, user is unauthorised.
            Router.push(`/user/auth/logout?referrer=${Constants.REFERRERS.LOGIN_REQUIRED}`);
            return '';
        }
    }

    /**
     * Logs the user out
     * It's possible the user is logged in via SR, in which case we redirect to SR logout
     */
    const logout = async () => {
        const oidc = getUserManager();
        const user = await oidc.getUser();
        // client_id will be present on the user profile if it was manually set via auto login or refresh flow.
        const userWasManuallySet = user?.profile['client_id'];
        // Clear local storage after a user logs out
        getLocalStorage()?.clear();
        if (!userWasManuallySet) {
            await oidc.signoutRedirect();
        } else {
            // Else it was an auto log in, we can just cleat the user.
            clearUser();
            window.location.href = '/';
        }
    };

    /** Handles post-logout */
    const postLogout = async (returnUrl?: string) => {
        const oidc = getUserManager();
        await oidc.signoutRedirectCallback();
        clearUser();
        if (returnUrl) window.location.replace(returnUrl);
    };

    /**
     * This refresh function is PRIVATE ONLY. do not expose this function.
     * It is meant to be used by the `tryRefresh` function only.
     */
    const refresh = (cancelToken?: CancelToken) => {
        const oidc = getUserManager();
        const request: ConnectTokenRequest = {
            grant_type: 'refresh_token',
            scope: oidc.settings.scope,
            client_id: oidc.settings.client_id,
            refresh_token: getRefreshToken()
        };
        return AuthApi.connectToken(
            getSettings().authority,
            request,
            cancelToken
        );
    }

    const me = async () => {
        const oidc = getUserManager();
        const user = await oidc.getUser();
        return user;
    }

    const isAuthenticated = (): boolean => {
        const accessToken = getAccessToken();
        if (!accessToken) return false;

        const isGuest = getIsGuest();
        // Guest does not count as authenticated.
        if (isGuest) return false;

        const decodedToken = parseJwt(accessToken);

        if (!decodedToken) return false;

        // Get expiration time from the decoded token
        const expirationTimeMs = decodedToken.exp * 1000; // Convert from seconds to milliseconds
        const currentTimeMs = Date.now();

        // Check if the token is expired
        return expirationTimeMs > currentTimeMs;
    }

    const getAccessToken = () => {
        return Cookie.get(accessTokenCookie);
    }

    const setAccessToken = (at: string) => {
        return Cookie.set(accessTokenCookie, at, {
            expires: daysInTenYears
        });
    }

    const clearAccessToken = () => {
        return Cookie.remove(accessTokenCookie);
    }

    const getRefreshToken = () => {
        return Cookie.get(refreshTokenCookie) ?? '';
    }

    const setRefreshToken = (token: string) => {
        return Cookie.set(refreshTokenCookie, token, {
            expires: daysInTenYears
        });
    }

    const clearRefreshToken = () => {
        return Cookie.remove(refreshTokenCookie);
    }

    const clearUser = async () => {
        const oidc = getUserManager();
        refreshTimeout.clear();
        clearAccessToken();
        clearRefreshToken();
        clearIsGuest();
        return await oidc.removeUser();
    }

    /**
     * Set the IS_GUEST cookie for one day.
     */
    const setIsGuest = () => {
        const oneDayMs = 60 * 1000 * 60 * 24;
        Cookie.set(AppConstants.COOKIES.IS_GUEST, 'true', { expires: new Date(new Date().getTime() + oneDayMs) });
    }

    /**
     * Clear the IS_GUEST cookie.
     */
    const clearIsGuest = () => {
        Cookie.remove(AppConstants.COOKIES.IS_GUEST);
    }

    /**
     * Get wether or not the IS_GUEST flag is set.
     */
    const getIsGuest = (): boolean => {
        return !!Cookie.get(AppConstants.COOKIES.IS_GUEST);
    }

    return {
        me,
        getAccessToken,
        isAuthenticated,
        autoLogin,
        login,
        logout,
        postLogin,
        postLogout,
        clearAccessToken,
        clearUser,
        setAccessToken,
        getRefreshToken,
        setRefreshToken,
        tryRefresh,
        clearRefreshToken,
        // Guest methods
        setIsGuest,
        getIsGuest,
        clearIsGuest
    }
})()
