import { Street } from '@infotrack/infotrackgo.web.core/models';
import { CancelToken } from 'axios';
import { Api } from '~/api/api';
import { invalidatesUserProfileCache } from '~/framework/accounts/invalidateUserProfileCache';

// Sync the interested streets for user...
export const syncStreets = (
    streets: Street[],
    cancelToken: CancelToken
) => {
    return invalidatesUserProfileCache(() => Api.post<void>(`accounts/users/streets`, streets, { cancelToken }));
};
