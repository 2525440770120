import { ServiceToOrder } from '@infotrack/infotrackgo.web.core/models';
import { Discount } from '~/models/Responses/Discounts/Discount';

/**
 *  A function that loads in a discount record and provides us with a check function so
 *  that we can check a cart item agains a discount code to see where or not the cart item
 *  is potentially applicable.
 */
export const DiscountCodeApplicator = (discount: Discount) => {
    const restrictiveFields = {
        serviceId: discount.serviceId,
        serviceTypeId: discount.serviceTypeId,
        stateId: discount.stateId,
        countryId: discount.countryId
    };
    const restrictionValidators = {
        serviceId: (cartItem: ServiceToOrder, dc: Discount) => cartItem.id === dc.serviceId,
        serviceTypeId: (cartItem: ServiceToOrder, dc: Discount) => (cartItem.serviceTypeId ?? cartItem.serviceType) === dc.serviceTypeId,
        stateId: (cartItem: ServiceToOrder, dc: Discount) => cartItem.stateId === dc.stateId,
        countryId: (cartItem: ServiceToOrder, dc: Discount) => cartItem.countryId === dc.countryId
    }
    const validatorFuncs: ((cartItem: ServiceToOrder, discount: Discount) => boolean)[] = Object.keys(restrictiveFields)
        // Get only truthy keys to test.
        .filter(key => restrictiveFields[key])
        .map(key => restrictionValidators[key]);

    const check = (serviceToOrder: ServiceToOrder) => {
        return validatorFuncs.every(validatorFunc => validatorFunc(serviceToOrder, discount))
    }

    return { check }
}