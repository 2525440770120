import { getLocalStorage } from '@infotrack/infotrackgo.web.core/framework/utils/getLocalStorage';
import { iterableToArray } from '@infotrack/infotrackgo.web.core/framework/utils/iterableToArray';
import { DiscountCode } from '~/models/Responses/Discounts/DiscountCode';

// An API to store and retrieve the discount codes in local storage.
export const discountCodePersister = (() => {
    const key = 'discountCodes';

    // Persist the discount codes in local storage.
    const set = (mp: Map<string, DiscountCode>) => {
        const ls = getLocalStorage();
        if (!ls) return;
        const jsonObj = {};
        const values = iterableToArray(mp.values());
        values.forEach(val => jsonObj[val.code] = val);
        try {
            ls.setItem(key, JSON.stringify(jsonObj));
        } catch { }
    };

    // Get the discount codes from local storage.
    const get = (): Map<string, DiscountCode> | null => {
        const ls = getLocalStorage();
        if (!ls) return null;
        try {
            const obj = JSON.parse(ls.getItem(key) ?? '');
            return new Map(Object.entries(obj));
        } catch {
            return null;
        }
    };

    return { get, set };
})();