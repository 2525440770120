import { Suburb } from '@infotrack/infotrackgo.web.core/models';
import { CancelToken } from 'axios';
import { Api } from '~/api/api';
import { invalidatesUserProfileCache } from '~/framework/accounts/invalidateUserProfileCache';

// Sync the interested suburbs for user...
export const syncSuburbs = (
    suburbs: Suburb[],
    cancelToken: CancelToken
) => {
    return invalidatesUserProfileCache(() => Api.post<void>(`accounts/users/suburbs`, suburbs, { cancelToken }));
};
