import { ComponentsOverrides, ComponentsProps } from '@mui/material';
import { Theme } from '@mui/system';
import { muiColoursTheme } from './muiColoursTheme';

// Custom styles for MuiMenuItems
export const muiMenuItemTheme: {
    defaultProps?: ComponentsProps['MuiMenuItem'];
    styleOverrides?: ComponentsOverrides<Theme>['MuiMenuItem']
} = {
    styleOverrides: {
        root: {
            color: muiColoursTheme.ITG_BLACK,
            // Styles for the selected item from a muiMenu and its children (e.g. ListItemIcon, ListItemText, etc...)
            '&.Mui-selected, &.Mui-selected > *': {
                backgroundColor: 'transparent',
                color: muiColoursTheme.ITG_BLUE
            }
        }
    }
};
