import { makeAutoObservable } from 'mobx';
import { PasswordFormValues } from '~/components/forms/UserSignUpForm/PasswordForm';
import { SignUpFormValues } from '~/components/forms/UserSignUpForm/types/SignUpFormValues';
import { Constants } from '@infotrack/infotrackgo.web.core/framework/constants/constants';
import { DiscountFormValues } from '~/models/Discounts/DiscountFormValues';
import { StandardFormStore } from './standardFormStore';
import { PoliceCheckFormData } from '@infotrack/infotrackgo.web.core/models';

// A forms store where we group all of our StandardFormStore implementations...
export class FormsStore {
    public signUpFormStore = new StandardFormStore<SignUpFormValues>(Constants.LOCAL_STORAGE.SIGN_UP_FORM);
    public passwordFormStore = new StandardFormStore<PasswordFormValues>(Constants.LOCAL_STORAGE.SIGN_UP_PASS_FORM);
    public policeCheckFormStore = new StandardFormStore<PoliceCheckFormData>(Constants.LOCAL_STORAGE.POLICE_CHECK_FORM);
    public discountFormStore = new StandardFormStore<DiscountFormValues>(Constants.LOCAL_STORAGE.DISCOUNT_FORM);

    constructor() {
        makeAutoObservable(this);
    }
}
