import { AlertColor, SnackbarCloseReason, SnackbarContentProps } from '@mui/material';
import { makeAutoObservable } from 'mobx';
import { loadInstanceVariables } from '@infotrack/infotrackgo.web.core/framework/utils/loadInstanceVariables';

export class MuiSnackbar {
    title?: string = undefined;
    message: string = '';
    severity?: AlertColor = undefined;
    autoHideDuration?: number = undefined;
    action?: SnackbarContentProps['action'] = undefined;
    customContent?: JSX.Element = undefined;

    constructor(opts?: Partial<MuiSnackbar>) {
        loadInstanceVariables(this, opts);
    }
}

// Note that for a better UX, we follow the MUI recomendations of just displaying one snackbar at a time.
export class SnackbarStore {
    open: boolean = false;
    snackbar: MuiSnackbar = new MuiSnackbar();

    constructor() {
        makeAutoObservable(this);
    }

    public openSnackbar = (muiSnackbarProps: MuiSnackbar) => {
        this.open = true;
        this.snackbar = new MuiSnackbar({...muiSnackbarProps});
    };

    // event is required for the Snackbar onClose. Do not remove
    public closeSnackbar = (reason?: SnackbarCloseReason) => {
        if (reason === 'clickaway') return;

        this.open = false;
        this.snackbar = new MuiSnackbar();
    };
}