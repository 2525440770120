import { ClassificationLabels, ServiceTypes } from '@infotrack/infotrackgo.web.core/enums';
import { Constants, getLocalStorage, isOnSearchDomain } from '@infotrack/infotrackgo.web.core/framework';
import { CompanySearchFilters, ISearchFilters, TitleSearchFilters, PersonSearchFilters, VehicleSearchFilters } from '@infotrack/infotrackgo.web.core/models/Search/Filters';
import { makeAutoObservable } from 'mobx';
import { StandardFormStore } from '../forms/standardFormStore';
import { VerticalClassification, VerticalSearchForm } from '@infotrack/infotrackgo.web.core/models';
import { GAApi } from '~/framework/analytics/GAApi';

export interface SearchResults { }

/**
 * Retrieve query from DOM due to react context(used by useRouter) being undefined during mobex startup
 */
const getQueryStrings = () => {
    let queryString = window.location.search;
    queryString = queryString.slice(1);
    let queryParams = queryString.split('&');

    let params = {};
    queryParams.forEach(function (param) {
        const keyValue = param.split('=');
        const key = decodeURIComponent(keyValue[0]);
        const value = decodeURIComponent(keyValue[1] ?? '');
        params[key] = value;
    });

    return params;
}

/**
 * Trim string properties. Useful when parsing URL params into ISearchFilters, removing white-spaces that can influence the search result
 */
const trimStringProperties = (obj: any) => {
    if (typeof obj !== 'object' || obj === null) return obj;

    for (let key in obj) {
        if (typeof obj[key] === 'string') obj[key] = obj[key].trim();
        else if (typeof obj[key] === 'object') return trimStringProperties(obj[key]);
    }

    return obj;
}

/**
 * Sync the URL Params into the vertical store, so we can update the search form and display up to date into when user comes from WordPress
 */
const syncSearchQueryParamsIntoStore = (
    routeArgs: any,
    selectedVerticalFormStore: StandardFormStore<VerticalSearchForm<ISearchFilters>>
) => {
    let filters = selectedVerticalFormStore?.formValues?.filters ?? {};
    const trimmedArgs = trimStringProperties(routeArgs['args']);

    // Any route changes (e.g. traffic from WordPress) takes priority.
    if (trimmedArgs) filters = JSON.parse(trimmedArgs);

    selectedVerticalFormStore?.sync(true,
    {
        searchTerm: routeArgs['s']?.trim() ?? '',
        classification: { label: routeArgs!['label'] ?? ClassificationLabels.Unknown, suggestId: routeArgs!['suggestId'] },
        filters: filters
    }, []);
}

/**
 * Retrieve the query params when user is coming from WordPress ONLY, so we can sync the search fields into the store
 */
const getUrlSearchRouteArgs = (): any => {
    if (typeof window === 'undefined') return undefined;

    const routeArgs = isOnSearchDomain()
    ? getQueryStrings()
    : undefined;

    return routeArgs && routeArgs['referrer'] === 'WP' ? routeArgs : undefined;
}

/**
 * Get Vertical from local storage with the proper enum value of type ServiceTypes Enum
 */
const _getSelectedVerticalFromLs = (): ServiceTypes => {
    if (typeof window === 'undefined') return ServiceTypes.Property;

    // if loaded from WordPress
    const routeArgs = isOnSearchDomain()
        ? getQueryStrings()
        : undefined;

    const routeCategory = routeArgs && routeArgs['cat']
        ? routeArgs['cat']
        : undefined;

    let lsSelectedVertical = '';
    if (routeCategory) {
        let enumValue = ServiceTypes[routeCategory] as unknown as ServiceTypes;
        lsSelectedVertical = `${enumValue}`;
        // If from WP, sync on LS, so when the user moves around the same vertical is selected ultil the user changes
        _setSelectedVerticalOnLs(+lsSelectedVertical);
    } else lsSelectedVertical = getLocalStorage()?.getItem(Constants.LOCAL_STORAGE.SELECTED_VERTICAL_SEARCH) ?? '0';

    const entries = Object.entries(ServiceTypes);
    for (const [key, value] of entries) {
        if (value === Number(lsSelectedVertical)) return value;
    }

    return ServiceTypes.Property;
}

const _setSelectedVerticalOnLs = (selectedVertical: ServiceTypes | string) => {
    getLocalStorage()?.setItem(
        Constants.LOCAL_STORAGE.SELECTED_VERTICAL_SEARCH,
        JSON.stringify(selectedVertical)
    );
}

export class SearchStore {
    public selectedVertical: ServiceTypes = _getSelectedVerticalFromLs();
    public verticalSearchForms: Map<ServiceTypes, StandardFormStore<VerticalSearchForm<ISearchFilters>>> =
        new Map<ServiceTypes, StandardFormStore<VerticalSearchForm<any>>>([
            [ServiceTypes.Property, new StandardFormStore<VerticalSearchForm<TitleSearchFilters>>('property-search-form')],
            [ServiceTypes.Company, new StandardFormStore<VerticalSearchForm<CompanySearchFilters>>('company-search-form')],
            [ServiceTypes.Person, new StandardFormStore<VerticalSearchForm<PersonSearchFilters>>('person-search-form')],
            [ServiceTypes.Vehicle, new StandardFormStore<VerticalSearchForm<VehicleSearchFilters>>('vehicle-search-form')],
        ]
    );

    constructor() {
        // if coming from WordPress, set the form initial values and sync for subsequent searches
        const wpRouteArgs = getUrlSearchRouteArgs();
        if (wpRouteArgs) {
            const selectedVerticalFormStore = this.getVerticalSearchForm(this.selectedVertical);
            syncSearchQueryParamsIntoStore(wpRouteArgs, selectedVerticalFormStore!);
        }

        makeAutoObservable(this);
    }

    private _clear = () => {
        getLocalStorage()?.removeItem(Constants.LOCAL_STORAGE.SELECTED_VERTICAL_SEARCH);
    }

    public getVerticalSearchForm = (serviceType: ServiceTypes) => this.verticalSearchForms.get(serviceType);

    public setSelectedVertical = (serviceType: ServiceTypes) => {
        this.selectedVertical = serviceType;
        _setSelectedVerticalOnLs(serviceType);

        // Send the selected search vertical / content to GA
        GAApi().event('select_content', { content_type: ServiceTypes[serviceType]})
    }

    public getVerticaSearchTerm = (serviceType: ServiceTypes) => {
        const registry = this.getVerticalSearchForm(serviceType);

        return registry?.formValues?.searchTerm ?? '';
    }

    public setVerticalSearchTerm = (searchTerm: string) => {
        const registry = this.getVerticalSearchForm(this.selectedVertical);

        if (registry) {
            let currentFormValues = registry.formValues ?? new VerticalSearchForm<ISearchFilters>();
            currentFormValues = { ...currentFormValues, searchTerm: searchTerm };
            registry.setFormValues(currentFormValues);
        }
    }

    public setVerticalClassification = (classification: VerticalClassification) => {
        const registry = this.getVerticalSearchForm(this.selectedVertical);

        if (registry) {
            let currentFormValues = registry.formValues ?? new VerticalSearchForm<ISearchFilters>();
            currentFormValues = { ...currentFormValues, classification: classification };
            registry.setFormValues(currentFormValues);
        }
    }

    setVerticalFilters = (filters: ISearchFilters) => {
        const registry = this.getVerticalSearchForm(this.selectedVertical);

        if (registry) {
            let currentFormValues = registry.formValues ?? new VerticalSearchForm<ISearchFilters>();
            currentFormValues = { ...currentFormValues, filters: filters };
            registry.setFormValues(currentFormValues);
        }
    }

    public buildItgRouterArgsQuery = () => {
        const verticalSearch = this.getVerticalSearchForm(this.selectedVertical);
        const query = {
            cat: ServiceTypes[this.selectedVertical],
            label: verticalSearch?.formValues?.classification?.label,
            args: JSON.stringify(verticalSearch?.formValues?.filters),
            s: verticalSearch?.formValues?.searchTerm,
            suggestId: verticalSearch?.formValues?.classification?.suggestId
        };
        return query;
    }
}
