import { PaletteOptions } from '@mui/material';
import { muiColoursTheme } from './muiColoursTheme';

export const muiPaletteTheme: PaletteOptions = {
    primary: {
        main: muiColoursTheme.ITG_ORANGE,
        contrastText: muiColoursTheme.ITG_WHITE
    },
    secondary: {
        main: muiColoursTheme.ITG_BLUE,
        contrastText: muiColoursTheme.ITG_WHITE
    },
    info: {
        dark: muiColoursTheme.ITG_PURPLE,
        main: muiColoursTheme.ITG_BLUE,
        light: muiColoursTheme.ITG_BLUE
    },
    success: {
        main: muiColoursTheme.ITG_GREEN,
    },
    error: {
        main: muiColoursTheme.ITG_RED,
    },
    background: {
        default: muiColoursTheme.ITG_WHITE,
        paper: muiColoursTheme.ITG_WHITE,
    }
};