import { loadInstanceVariables } from '@infotrack/infotrackgo.web.core/framework/utils/loadInstanceVariables';
import { ContactPreference } from './ContactPreferenceRequest';
import { State, Suburb, Street, Address } from '@infotrack/infotrackgo.web.core/models';

// The data returned from the /profile endpoint.
export interface IUserProfile {
    states: State[];
    suburbs: Suburb[];
    streets: Street[];
    addresses: Address[];
    hasOptedOutOfNotifications: boolean;
    messagingTypePreference: number;
    userLoginId: number;
}

export class UserProfile implements IUserProfile {
    states: State[] = [];
    suburbs: Suburb[] = [];
    streets: Street[] = [];
    addresses: Address[] = [];
    hasOptedOutOfNotifications: boolean;
    messagingTypePreference: number;
    userLoginId: number;

    constructor(opts?: Partial<IUserProfile>) {
        loadInstanceVariables(this, opts);
        // Make sure addresses are always instance types.
        if (this.addresses) this.addresses = this.addresses.map(add => new Address(add));
    }

    public isEmpty() {
        return (
            !this.states?.length
            && !this.suburbs?.length
            && !this.streets?.length
            && !this.addresses?.length
        );
    }

    public hasAddress(address: Address): boolean {
        return Boolean(this.addresses.find(add => add.equals(address)));
    }

    public getContactPreference(): ContactPreference {
        if (this.hasOptedOutOfNotifications) return ContactPreference.DoNotContact;
        return this.messagingTypePreference;
    }
}
