export const Constants = {
    STRINGS: {
        NEW_LINE: 'NEW_LINE'
    },
    AUS_STATES: [
        'NSW', 'QLD', 'VIC', 'SA', 'WA', 'NT', 'ACT', 'TAS'
    ],
    MOVE_ME_IN_PARTNER_ID: 2,
    PARTNER_TRACKER_WEBSITE_SOURCE_ID: 1,
    PARTNER_TARCKER_EMAIL_SOURCE_ID: 2,
    THIRD_PARTY: {
        MOVE_ME_IN_WEBSITE: 'https://movemein.com.au/'
    },
    GOOGLE: {
        // The default lat and long values if api fails.
        SYDNEY_LAT: 33.8688,
        SYDNEY_LNG: 151.2093,
        // Radius from the set lat and long values based to google.
        RADIUS_IN_METERS: 1000000,
        GOOGLE_PAY_VERSION: 2
    },
    APPLE: {
        APPLE_PAY_VERSION: 3
    },
    USER: {
        PROFILE_CACHE_INVALID_KEY: 'profile-cache-invalid'
    },
    DEBOUNCE: {
        STANDARD: 400,
        FASTER: 300,
        FASTEST: 150
    },
    LOCAL_STORAGE: {
        TRACKING_INFO: 'tracking_info',
        // Forms
        SIGN_UP_FORM: 'signup_form',
        POLICE_CHECK_FORM: 'police_check_form',
        SIGN_UP_PASS_FORM: 'signup_pass_form',
        SIGN_UP_FORM_USERNAME_STATUS: 'signup_form_userstatus',
        // Checkout Steps
        CURRENT_CHECKOUT_STEP: 'checkout-current-step',
        ORDER_STEP_SELECTED_OPTION_KEY: 'order-step-option',
        SIGNUP_MODE: 'signup-mode',
        DISCOUNT_FORM: 'discount-form'
    },
    COOKIES: {
        IS_GUEST: 'is_guest'
    },
    STATIC_SERVICE_IDS: {
        NSW_TITLE_SEARCH: 1
    }
};
