import { PageProps } from '~/models/App/PageProps';
import { InitialAppState } from '~/stores/stores';
import { getTrackingInformation } from './getTrackingInformation';

export const getQueryParameters = (ctx: PageProps): InitialAppState => {
    const defaults: InitialAppState = {
        trackingInformation: {}
    };

    if (!ctx.query) {
        return defaults;
    }

    return {
        trackingInformation: getTrackingInformation(ctx)
    };
};
