import { loadInstanceVariables } from '@infotrack/infotrackgo.web.core/framework';
import { AppStore } from '../AppStore';
import { CheckoutWorkflowStepTitles } from './CheckoutWorkflowStepTitles';

export class CheckoutStep {
    readonly name: CheckoutWorkflowStepTitles;
    checkFunc: (value: AppStore) => boolean;

    constructor(opts?: Partial<CheckoutStep>) {
        loadInstanceVariables(this, opts);
    }

    public isComplete(store: AppStore): boolean {
        if (typeof this.checkFunc === 'function') return this.checkFunc(store);

        return false;
    }
}