import { makeAutoObservable } from 'mobx';
import { PaymentTypes } from '@infotrack/infotrackgo.web.core/enums/PaymentTypes';
import { ServiceTypes } from '@infotrack/infotrackgo.web.core/enums/ServiceTypes';
import { LineItem } from '~/models/Payment/PayPal';
import { RequestPaymentPayload } from '~/models/Payment/RequestPaymentPayload';
import { ServiceToOrder } from '@infotrack/infotrackgo.web.core/models';
import { getMobxStores } from '~/pages/_app';
import { handleBraintreeError } from '~/framework/payment/braintree/handleBraintreeError';

export interface BraintreePaymentMethodDetails {
  nonce: string;
  paymentMethod: PaymentTypes;
}

export class BraintreePaymentStore {

  public paymentMethodIsValid: boolean = false;

  public dropinInstance: any | undefined = undefined;

  public braintreeScriptLoaded = false;

  constructor() {
    makeAutoObservable(this);
  }

  public setBraintreeScriptLoaded = (loaded: boolean) => {
    this.braintreeScriptLoaded = loaded;
  };

  public setdropinInstance = (instance: any) => {
    this.dropinInstance = instance;
  };

  public setPaymentMethodIsValid = (isValid: boolean) => {
    this.paymentMethodIsValid = isValid;
  }

  public getPayPalLineItems(servicesToOrder: ServiceToOrder[], subTotal: number): LineItem[] {
    const LineItems: LineItem[] = [];
    servicesToOrder.forEach((cartItem) => {

      const serviceName = (
        cartItem.name ?
        `${cartItem.name || ''} - ${cartItem.searchTerm || ''}` :
        cartItem.searchTerm || ''
      );

      const LineItem: LineItem = {
        quantity: '1',
        unitAmount: `${cartItem.fee || ''}`,
        kind: `${ServiceTypes[cartItem.serviceType]}`,
        name: serviceName
      };
      LineItems.push(LineItem);
    });

    // The paypal amount must be equals to sum of LineItems's 'unitAmount'.
    const paypalFeeLineItem: LineItem = {
      quantity: '1',
      unitAmount: `${this.getPaypalFee(subTotal)}`,
      kind: 'Surcharge',
      name: 'PayPal Surcharge'
    };

    LineItems.push(paypalFeeLineItem);
    return LineItems;
  }

  public getPaypalFee(subTotal: number): number {
    return (0.024 * subTotal) + 0.3;
  }

  public getPayPalTotalAmount(subTotal: number): number {
    subTotal = subTotal + this.getPaypalFee(subTotal);
    return subTotal;
  }

  public getPaymentMethod(paymentType: string | PaymentTypes) {
    if (typeof paymentType === typeof PaymentTypes) return PaymentTypes[paymentType];
    const cardTypeName: Array<string> = paymentType.split(' ');
    cardTypeName.forEach(element => element.trim());
    return PaymentTypes[cardTypeName.join('')];
  }

  // Method to get credentials from braintree drop in UI for ordering...
  public requestBraintreePaymentMethodDetails(): Promise<BraintreePaymentMethodDetails | null> {
    return new Promise(resolve => {
      this.dropinInstance.requestPaymentMethod((requestPaymentMethodErr, payload: RequestPaymentPayload) => {
        // Cover err use case...
        if (requestPaymentMethodErr) {
          this.setPaymentMethodIsValid(false);
          handleBraintreeError(requestPaymentMethodErr, 'Failed to request the payment method');
          return resolve(null);
        }
        return resolve({
          paymentMethod: this.getPaymentMethod(payload.details?.cardType || payload?.type),
          nonce: payload.nonce
        });
      });
    });
  }
}
