import { ITrackingInformation, TrackingInformation } from '~/models/App/TrackingInformation';
import { AppStore } from '~/stores/AppStore';
import { Constants } from '../constants/constants';
import { upsertLocalStorage } from '@infotrack/infotrackgo.web.core/framework';

// Look for the value first in local storage,
// if it is there, return that.
// else set in local storage and return.
export const upsertTrackingInfo = (appStore: AppStore, trackingInfo: ITrackingInformation) => {
    const expiryPeriod = 90 * 24 * 60 * 60 * 1000; // 90 day expiry in milliseconds
    const expiry = new Date().getTime() + expiryPeriod;

    trackingInfo.gclidExpiry = expiry;

    const value = upsertLocalStorage(
        Constants.LOCAL_STORAGE.TRACKING_INFO,
        trackingInfo,
        {
            expiryHandler: (lsValue) => {
                // If there was a new gclid passed in, then invalidate the old one.
                if (trackingInfo.gclid && lsValue?.gclid !== trackingInfo.gclid) return false;
                const value = new TrackingInformation(lsValue);
                const isGclidValid = !!(value.gclid && new Date().getTime() < (value.gclidExpiry || 0));
                return isGclidValid;
            }
        }
    );

    appStore.setTrackingInformation(value);
    return value;
};