import { RumInitConfiguration } from "@datadog/browser-rum";
import { UserManagerSettings } from "oidc-client-ts";

type Environments = 'development' | 'test' | 'stage' | 'production';

export interface DatadogConfig {
  clientToken: string;
  site: string;
  env: string;
  service: string;
}

// Note, this is the ONLY place where process.env should be accessed in all of the FE ts code.
// It is the only way to guarantee we get the values from the env (See setup in _app.tsx) ~ Ale ;)
// Also - ensure that no key specified here is confidential, these keys will be used in the browser...
// Also also the 'get' functions in this settings class can not be used anywhere else in the app as
// APP_ENV will be undefined.
// tslint:disable-next-line: cyclomatic-complexity
export class Setting {
    public GoogleRecaptchaSiteKey: string = process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_KEY || '';
    public GoogleMapsApiKey: string = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || '';

    public Environment: Environments = (process.env.APP_ENV || process.env.NEXT_PUBLIC_APP_ENV) as Environments;
    public ApiEndpoint: string = process.env.NEXT_PUBLIC_API_ENDPOINT ?? '';

    public GoogleAnalyticsTrackingID: string = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID || '';
    public GoogleAnalyticsMeasurementID: string = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID || '';
    public GoogleAnalyticsAdwordsId: string = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ADWORDS_ID || '';
    public GoogleMerchantId: string = process.env.NEXT_PUBLIC_GOOGLE_MERCHANT_ID || '';

    public BrainTreeClientToken: string = process.env.NEXT_PUBLIC_BRAINTREE_CLIENT_TOKEN || '';

    public IdentityConfig: UserManagerSettings = {
        authority: process.env.NEXT_PUBLIC_APP_AUTH_URL || 'https://auth.infotrack.com.au',
        client_id: process.env.NEXT_PUBLIC_APP_IDENTITY_CLIENT_ID || 'ITG_pkce',
        redirect_uri: process.env.NEXT_PUBLIC_APP_REDIRECT_URL || 'https://search.infotrackgo.com.au/user/auth/callback',
        post_logout_redirect_uri: process.env.NEXT_PUBLIC_APP_LOGOFF_REDIRECT_URL || 'https://search.infotrackgo.com.au/user/auth/logout/callback',
        response_type: 'code',
        scope: 'infsec:identity openid infsec:basic profile offline_access',
        // Forces users to log back in/re-authenticate.
        automaticSilentRenew: false,
        filterProtocolClaims: false
    };

    public DatadogConfig: DatadogConfig = {
        clientToken: process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN || '',
        site: process.env.NEXT_PUBLIC_DD_SITE || 'datadoghq.com',
        env: !this.Environment || this.Environment?.charAt(0).toLowerCase() === 'p' ? 'prod' : this.Environment,
        service: process.env.NEXT_PUBLIC_DD_SERVICE || 'infotrackgo-web'
    };

    public DatadogRumConfig: RumInitConfiguration = {
        applicationId: process.env.NEXT_PUBLIC_DD_RUM_APPLICATION_ID || '',
        clientToken: process.env.NEXT_PUBLIC_DD_RUM_CLIENT_TOKEN || '',
        site: process.env.NEXT_PUBLIC_DD_SITE || 'datadoghq.com',
        env: !this.Environment || this.Environment?.charAt(0).toLowerCase() === 'p' ? 'prod' : this.Environment,
        service: process.env.NEXT_PUBLIC_DD_SERVICE || 'infotrackgo-web',
        allowedTracingUrls: [{ match: `https://${this.Environment?.charAt(0).toLowerCase() === 's' ? 'stage' : ''}search.infotrackgo.com.au`, propagatorTypes: ['datadog'] }],
    }

    public IsDevTesting: boolean = !!process.env.NEXT_PUBLIC_IS_DEV_TESTING;
}

export const Settings = { settings: new Setting() };