import { ComponentsOverrides, ComponentsProps, Theme } from '@mui/material';
import { muiColoursTheme } from './muiColoursTheme';

export const muiCheckboxTheme: {
    defaultProps?: ComponentsProps['MuiCheckbox'];
    styleOverrides?: ComponentsOverrides<Theme>['MuiCheckbox'];
} = {
    styleOverrides: {
        root: {
            '&$checked': {
                color: muiColoursTheme.ITG_BLUE
            }
        }
    }
};