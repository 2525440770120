import { ProcessedDiscount } from '~/models/Discounts/ProcessedDiscount';
import { ServiceToOrder } from '@infotrack/infotrackgo.web.core/models';
import { DiscountCode } from '~/models/Responses/Discounts/DiscountCode';
import { discountConditionIsSatisfied } from './discountConditionIsSatisfied';
import { getDiscountCodeType } from './getDiscountCodeType';
import { DiscountCodeType } from '~/enums/payment/discounts/DiscountCodeType';

type DiscountCodeValidationResponse = [DiscountCodeType, ProcessedDiscount[]]

/**
 * See if the use case of the discount code is valid and if we should
 * proceed or not...
 */
export const validateDiscountCode = (discountCode: DiscountCode, cartItems: ServiceToOrder[]): DiscountCodeValidationResponse => {
    // Check for main type of discount.
    const dType = getDiscountCodeType(discountCode);
    if (dType === null || dType === DiscountCodeType.Invalid) return [DiscountCodeType.Invalid, []]; // Doesn't apply...

    const processedDiscounts: ProcessedDiscount[] = [];
    let cascadeValid = true;
    let totalSatisifed = 0;

    // Maps service uuids to how many times that service has satisfied a discount condition.
    const servicesMap = new Map<string, number>();

    // Loop each discount record in the chain and validate whether or not it's conditions are satisified.
    discountCode.discounts.forEach(discount => {
        const processedDiscount: ProcessedDiscount = {
            ...discount,
            conditionIsSatisfied: discountConditionIsSatisfied(discount, cartItems, servicesMap)
        };
        if (processedDiscount.conditionIsSatisfied) totalSatisifed += 1;
        // If this condition is satisfied BUT the last one isn't, then cascade valid is false.
        if (
            processedDiscount.conditionIsSatisfied &&
            processedDiscounts.length &&
            !processedDiscounts[processedDiscounts.length - 1].conditionIsSatisfied
        ) {
            // Invalidate cascade if last item was not truthy but this one is!
            cascadeValid = false;
            processedDiscount.conditionIsSatisfied = false;
        }
        processedDiscounts.push(processedDiscount);
    });

    let isValid = false;
    // If DC is a package, ensure all discounts in the chain have been satisified.
    if (dType === DiscountCodeType.Package) isValid = totalSatisifed === discountCode.discounts.length;
    // If DC unrestricted, ensure there is only one element in the chain.
    else if (dType === DiscountCodeType.Unrestricted) isValid = processedDiscounts.length === 1;
    // Else is of type "Cascade" and we need at least one
    else isValid = totalSatisifed > 0;

    if (isValid) return [dType, processedDiscounts];
    return [DiscountCodeType.Invalid, processedDiscounts];
};