import { ProcessedDiscount } from '~/models/Discounts/ProcessedDiscount';
import { ServiceToOrder } from '@infotrack/infotrackgo.web.core/models';
import { Discount } from '~/models/Responses/Discounts/Discount';
import { DiscountCodeApplicator } from './discountCodeApplicator';
import { DiscountCodeType } from '~/enums/payment/discounts/DiscountCodeType';

const getCascadeDiscounts = (highestOrderIndex: number, discounts: ProcessedDiscount[]): ProcessedDiscount[] => {
    const highestOrderDiscount = discounts[highestOrderIndex];
    return discounts.map((discount, i) => {
        const shouldApplyCascade = i <= highestOrderIndex;
        // If the cascade should be applied, plug the highest order
        // discount values onto all previous discounts in the chain.
        if (shouldApplyCascade) return {
            ...discount,
            percentage: highestOrderDiscount.percentage,
            amount: highestOrderDiscount.amount
        };
        return { ...discount };
    });
};

/**
 * Get the discounts that should be applied.
 * If it is package, then all.
 * If cascade, then the highest order.
 * If unrestricted then all.
 */
const getDiscountsToApply = (dType: DiscountCodeType, discounts: ProcessedDiscount[]): ProcessedDiscount[] => {
    if (dType !== DiscountCodeType.Cascade) return [...discounts];
    // Else we need to find the highest order, because it is the only one to be applied.
    const firstFailedIndex = discounts.findIndex(d => !d.conditionIsSatisfied);
    let highestOrderSuccessIndex = (
        firstFailedIndex === -1 ?
        // If none failed, get last index pos.
        discounts.length - 1 :
        // Else get the first failed index - 1 for the highest succesfull index.
        firstFailedIndex - 1
    );
    // Safeguard...
    if (highestOrderSuccessIndex < 0) highestOrderSuccessIndex = 0;
    return getCascadeDiscounts(highestOrderSuccessIndex, discounts);
};

/**
 * Assigns a discount to each applicable service, this lets us know
 * how much off each service should be discounted...
 */
export const assignDiscountsToCartItems = (
    code: string,
    dType: DiscountCodeType,
    processedDiscounts: ProcessedDiscount[],
    cartItems: ServiceToOrder[]
) => {
    // Get the discounts that should be applied.
    const discounts = getDiscountsToApply(dType, processedDiscounts);
    // Tally what discounts have been allocated, and how many times they have been.
    const discountsRecord = new Map<number, number>();

    const findNextDiscountForCartItem = (cartItem: ServiceToOrder) => {
        const discountAlreadyUsedCondition = (el: Discount) => (
            // Or we make sure that we have not hit the applyToInstances limit
            !el.applyToInstances
            || (discountsRecord.get(el.discountId) ?? 0) < el.applyToInstances
        );
        // Search the discounts for the discount to apply.
        const disc = discounts.find(el => (
            DiscountCodeApplicator(el).check(cartItem)
            && discountAlreadyUsedCondition(el)
        ));
        if (disc) {
            // Count that the discount was used.
            const allocatedDisc = discountsRecord.get(disc.discountId) ?? 0;
            discountsRecord.set(disc.discountId, allocatedDisc + 1);
            return disc;
        }
        return null;
    };

    const calculateDiscount = (amount: number, percentage: number, serviceTotal: number) => {
        if (!serviceTotal) return 0;
        if (amount) return amount;
        return serviceTotal * percentage;
    };

    return cartItems.map(cartItem => {
        const discount = findNextDiscountForCartItem(cartItem);
        if (cartItem.discountId || !discount?.conditionIsSatisfied) return { ...cartItem };
        return {
            ...cartItem,
            discountFee: calculateDiscount(discount.amount, discount.percentage, cartItem.fee ?? 0),
            discountId: discount?.discountId,
            discountCode: discount ? code : undefined
        };
    });
};