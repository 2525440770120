import { State } from '@infotrack/infotrackgo.web.core/models';
import { CancelToken } from 'axios';
import { Api } from '~/api/api';
import { invalidatesUserProfileCache } from '~/framework/accounts/invalidateUserProfileCache';

// Sync the interested states for user...
export const syncStates = (
    states: State[],
    cancelToken: CancelToken
) => {
    return invalidatesUserProfileCache(() => Api.post<void>(`accounts/users/states`, states, { cancelToken }));
};
