import { CancelToken } from 'axios';
import { Api } from '~/api/api';
import { invalidatesUserProfileCache } from '~/framework/accounts/invalidateUserProfileCache';

// Remove an address from the user's "Watchlist".
export const removeAddress = (
    addressId: number,
    cancelToken: CancelToken
) => {
    return invalidatesUserProfileCache(() => Api.del<void>(`accounts/users/addresses/${addressId}`, { cancelToken }));
};
