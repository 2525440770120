import React from 'react';
import { createContext, useContext } from 'react';
import { ITrackingInformation } from '~/models/App/TrackingInformation';
import { AppStore } from '~/stores/AppStore';

const isServer = typeof window === 'undefined';

let appStore: AppStore;

// Structure of the initial app state (usually coming from query parameters).
export interface InitialAppState {
    trackingInformation: ITrackingInformation;
}

// This type is what is injected via the inject method from mobx-react.
export type InjectedMobxValues = { store: AppStore; };

const createNewStore = (initialData?: any) => new AppStore(initialData ?? {} as any);

// Bootstrap the app store and consider server + permanence.
export function initialiseAppStore(initialData: InitialAppState) {
    if (!isServer && appStore) return appStore;

    appStore = createNewStore(initialData);
    return appStore;
}

// For functional components that will use our useStore() function
export const StoreContext = createContext(createNewStore());

export function StoreProvider(props: any) {
    return <StoreContext.Provider value={props.value}>{props.children}</StoreContext.Provider>;
}

// What we can use in functional components wrapped in `observer` so that
// values accessed from usedStore can update the component.
export function useStore() {
    return useContext(StoreContext);
}
