import { loadInstanceVariables } from '@infotrack/infotrackgo.web.core/framework/utils/loadInstanceVariables';

// Tracking information that we receive from google.
// For example when a google ad is clicked and then the user is
// directed to our site.
export interface ITrackingInformation {
    referrer?: string;

    campaignName?: string;

    campaignSource?: string;

    campaignMedium?: string;

    // Google click identifier, used to track conversions from ad spend.
    // when a user clicks on a google add the gclid of the ad gets appended to
    // the query string.
    gclid?: string;

    // When the above gclid will expire.
    gclidExpiry?: number;

    dclid?: string;

    // The source of the google click.
    gclsrc?: string;

    // A flag to test gclid/salesforce setup.
    debug?: number;
}

export class TrackingInformation implements ITrackingInformation {
    referrer?: string = '';
    campaignName?: string = '';
    campaignSource?: string = '';
    campaignMedium?: string = '';

    // Google click identifier, used to track conversions from ad spend.
    // when a user clicks on a google add the gclid of the ad gets appended to
    // the query string.
    gclid?: string = '';

    dclid?: string = '';

    gclidExpiry?: number = 0;

    // The source of the google click.
    gclsrc?: string = '';

    debug?: number = 0;

    constructor(opts?: Partial<ITrackingInformation>) {
        loadInstanceVariables(this, opts);
    }
}
