import { Address } from '@infotrack/infotrackgo.web.core/models';
import { CancelToken } from 'axios';
import { Api } from '~/api/api';
import { invalidatesUserProfileCache } from '~/framework/accounts/invalidateUserProfileCache';

export enum AddressInterestType {
    Interested = 1,
    Ownership = 2
}

// Add an address to the user's "Watchlist".
export const addAddress = (
    address: Address,
    addressInterestType: AddressInterestType,
    cancelToken: CancelToken
) => {
    const request: any = {
        address,
        addressInterestType
    };
    request.address.addressId = undefined;
    return invalidatesUserProfileCache<Address>(() => (
        Api.post<{ address: Address, addressInterestTypeId: number }>(`accounts/users/addresses`, request, { cancelToken })
            .then((data) => new Address(data.address))
    ));
};
