import { ServiceToOrder } from '@infotrack/infotrackgo.web.core/models';
import { DiscountCode } from '~/models/Responses/Discounts/DiscountCode';
import { assignDiscountsToCartItems } from './assignDiscountsToCartItems';
import { validateDiscountCode } from './validateDiscountCode';
import { DiscountCodeType } from '~/enums/payment/discounts/DiscountCodeType';

/**
 * Takes a discount code record and applies it to our cart items, this function is the main
 * entry point of the discounts application.
 *
 * @returns null if no discount is to be applied.
 * @returns ServiceToOrder[] with discountedFee, discountId and discountCode loaded on the objects if successfull.
 */
export const applyDiscountCodeToCartItems = (
    discountCode: DiscountCode,
    cartItems: ServiceToOrder[]
): null | ServiceToOrder[] => {
    // 1. Initial validation of the discount code.
    const [discountCodeType, processedDiscounts] = validateDiscountCode(discountCode, cartItems);
    if (!discountCodeType || discountCodeType === DiscountCodeType.Invalid) return null;

    // 2. Application of the discount code.
    const transformedCartItems: ServiceToOrder[] = assignDiscountsToCartItems(
        discountCode.code,
        discountCodeType as DiscountCodeType,
        processedDiscounts,
        cartItems
    );
    return transformedCartItems;
};
