export const muiColoursTheme = {
    // Orange.
    ITG_ORANGE: '#FFA500',
    ITG_ORANGE__LIGHT: '#FFB732',
    // Blue.
    ITG_BLUE: '#1FB6FF',
    ITG_BLUE__LIGHT: '#4BC4FF',
    ITG_BLUE__MUTED: '#EDFAFF',
    ITG_BLUE__DARK: '#2A82C4',
    // Green.
    ITG_GREEN: '#13CE66',
    ITG_GREEN__MUTED: '#EAFFF3',
    // Red.
    ITG_RED: '#BA0000',
    ITG_RED__LIGHT: '#F5222D',
    // Grey.
    ITG_GREY: '#E0E0E0',
    ITG_GREY__LIGHT: '#F8F8F8',
    ITG_GREY__DARK: '#868E96',
    // Black.
    ITG_BLACK: '#333',
    // White.
    ITG_WHITE: '#FFF',
    // Purple.
    ITG_PURPLE: '#AE46D3'
};