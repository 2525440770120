import { AppUser } from '~/models/User/AppUser';
import { CartStore } from '~/stores/user/cartStore';
import { BraintreePaymentStore } from '~/stores/user/BraintreePaymentStore';
import { makeAutoObservable, reaction } from 'mobx';
import { AccountsApi } from '~/api/accounts/accounts';
import { CreateAppUserResponse } from '~/models/User/CreateAppUser';
import { getLocalStorage } from '@infotrack/infotrackgo.web.core/framework/utils/getLocalStorage';
import { AutoLoginAppUserResponse } from '~/models/User/AutoLoginAppUserResponse';
import { IUserProfile, UserProfile } from '~/models/User/UserProfile';
import { logger } from '@infotrack/infotrackgo.web.core/framework/logging/logger';
import { UserRole } from '@infotrack/infotrackgo.web.core/enums/UserRole';
import { CheckoutStore } from '../checkout/checkoutStore';
import { Auth } from '~/framework/auth/auth';

export const appUserStorageKey = 'appUser';

const setUserOnLoggingContext = (userInfo?: AppUser) => {
    logger.setContext({
        userLoginId: userInfo?.userLoginId,
        userRole: userInfo?.userRole ? UserRole[userInfo.userRole] : undefined,
        token: Auth.getAccessToken()
    });
};

const loadUserFromLocalStorage = () => {
    const localStorage = getLocalStorage();
    const appUserFromLocalStorage = localStorage?.getItem(appUserStorageKey);
    if (appUserFromLocalStorage) {
        try {
            const appUser = new AppUser(JSON.parse(appUserFromLocalStorage));
            setUserOnLoggingContext(appUser);
            return appUser;
        } catch {
            return;
        }
    }
    return;
};

export class UserStore {
    public userInformation: AppUser | undefined = loadUserFromLocalStorage();

    public userProfile: UserProfile = new UserProfile();

    public profileHasLoaded: boolean;

    public profileIsLoading: boolean = false;

    // Sub Stores
    public cartStore: CartStore = new CartStore();

    public paymentStore: BraintreePaymentStore = new BraintreePaymentStore();

    public checkoutStore: CheckoutStore = new CheckoutStore();

    constructor() {
        makeAutoObservable(this);
    }

    get isLoggedIn(): boolean {
        return Boolean(
            this.userInformation
            && this.userInformation?.userRole !== UserRole.Guest
            && Auth.isAuthenticated()
        );
    }

    public updateProfileHasLoadedFromLocalStorage() {
        const userLoginId = this.userInformation?.userLoginId;
        const cacheKey = `get-profile|{"userLoginId":${userLoginId}}`;
        const hasLoaded = Boolean(getLocalStorage()?.getItem(cacheKey));
        this.profileHasLoaded = hasLoaded;
    }

    public updateUserInformation = (information: Partial<AppUser>) => {
        if (information && typeof information === 'object') {
            this.setUserInformation({
                ...this.userInformation,
                ...information
            });
        }
    };

    public updateUserFromCreated = async (createdAppUserReponse: CreateAppUserResponse) => {
        if (createdAppUserReponse && typeof createdAppUserReponse === 'object') {
            this.setUserInformation({
                userLoginId: createdAppUserReponse.userLoginId,
                name: createdAppUserReponse.name,
                email: createdAppUserReponse.email,
                isVerified: createdAppUserReponse.isVerified,
                mobileNumber: createdAppUserReponse.mobileNumber,
                userRole: createdAppUserReponse.userRole
            });
        }
    };

    public updateUserFromAutoLogin = async (autoLoginAppUserResponse: AutoLoginAppUserResponse) => {
        if (autoLoginAppUserResponse && typeof autoLoginAppUserResponse === 'object') {
        this.setUserInformation({
            userLoginId: autoLoginAppUserResponse.userLoginId,
            name: autoLoginAppUserResponse.name,
            email: autoLoginAppUserResponse.email,
            isVerified: autoLoginAppUserResponse.isVerified,
            mobileNumber: autoLoginAppUserResponse.mobileNumber,
            userRole: autoLoginAppUserResponse.userRole
        });
        }
    };

    public updateUserFromMeEndpoint = async () => {
        const userInfo = await AccountsApi.getMe();
        if (userInfo) this.setUserInformation(userInfo);
    }

    public setProfileIsLoading = (isLoading: boolean) => {
        this.profileIsLoading = isLoading;
    };

    public setProfileHasLoaded(hasLoaded: boolean) {
        this.profileHasLoaded = hasLoaded;
    }

    public setLoggedInUserAsVerified = (state: boolean) => {
        if (this.userInformation) {
            this.setUserInformation({...this.userInformation, isVerified: state});
            getLocalStorage()?.setItem(appUserStorageKey, JSON.stringify(this.userInformation));
        }
    }

    public saveUserOnLocalStorage = () => {
        if (this.userInformation) {
            const localStorage = getLocalStorage();
            localStorage?.setItem(appUserStorageKey, JSON.stringify(this.userInformation));
        }
    }

    public removeUserFromLocalStorage = () => {
        const localStorage = getLocalStorage();
        const appUserFromLocalStorage = localStorage?.getItem(appUserStorageKey);
        if (appUserFromLocalStorage) {
            localStorage?.removeItem(appUserStorageKey);
        }
    }

    public setUserInformation = (information: Partial<AppUser>) => {
        this.userInformation = new AppUser(information);
        this.saveUserOnLocalStorage();
        setUserOnLoggingContext(this.userInformation);
    }

    public removeUserInformation = () => {
        this.removeUserFromLocalStorage();
        this.userInformation = undefined;
        setUserOnLoggingContext(this.userInformation);
    }

    public setUserProfile = (profile: IUserProfile) => {
        this.userProfile = new UserProfile(profile);
    }
}