import { DiscountCodeType } from '~/enums/payment/discounts/DiscountCodeType';
import { DiscountCode } from '~/models/Responses/Discounts/DiscountCode';

// If all the amounts or percentages are the same then the discount code is of type "Package"
// Else it is of type "Cascade". "Package" or "Cascade" of course are the prime determinators of
// how we check that discount conditions have been satisified.
export const getDiscountCodeType = (dc: DiscountCode): DiscountCodeType | null => {
    const { discounts } = dc;
    if (!discounts || ! discounts.length) return null;
    const firstDiscount = discounts[0];

    if (
        discounts.some(discount => discount.amount != firstDiscount.amount) ||
        discounts.some(discount => discount.percentage != firstDiscount.percentage)
    ) return DiscountCodeType.Cascade;

    // If only one discount, it is unrestricted.
    if (discounts.length === 1) return DiscountCodeType.Unrestricted;
    if (discounts.every(dc => dc.amount === firstDiscount.amount && dc.percentage === firstDiscount.percentage))
    return DiscountCodeType.Package;
    return DiscountCodeType.Invalid;
};