import { makeAutoObservable, runInAction } from 'mobx';
import { ServiceTypes } from '@infotrack/infotrackgo.web.core/enums/ServiceTypes';
import { getAddOrRemoveGaEventPayload } from '@infotrack/infotrackgo.web.core/framework/analytics/gaDefaults';
import { logger } from '@infotrack/infotrackgo.web.core/framework/logging/logger';
import { createServiceToOrder } from '@infotrack/infotrackgo.web.core/framework/ordering/createServiceToOrder';
import { IServiceOrderRequest, ListServicesResult, ServiceToOrder } from '@infotrack/infotrackgo.web.core/models';
import { DiscountCode } from '~/models/Responses/Discounts/DiscountCode';
import { Service } from '@infotrack/infotrackgo.web.core/models';
import { getMobxStores } from '~/pages/_app';
import { applyDiscountCodeToCartItems } from '~/framework/payment/discounts/applyDiscountCodeToCartItems';
import { discountCodePersister } from '~/framework/payment/discounts/discountCodePersister';
import { GAApi } from '~/framework/analytics/GAApi';
import { CartDto } from '~/models/Cart/CartDto';
import { CartManager, OperationState } from '@infotrack/infotrackgo.web.core/framework';
import { Auth } from '~/framework/auth/auth';

export class CartStore {
    public cartId: number = 0
    public servicesToOrder: ServiceToOrder[] = [];
    public containsVicServiceToOrder: boolean = false;
    // Where we store the discount codes that someone enters in in the checkout.
    public activeDiscountCodes = new Map<string, DiscountCode>();
    private manager = CartManager();

    constructor() {
        if (typeof window !== 'undefined') {
            this.manager = CartManager(this._setCart);
        }
        makeAutoObservable(this);
    }

    public clearCartInLocalStorage = () => {
        this.manager.clear();
    };

    public getOrSetCart = () => this.manager.getOrSet(!!Auth.getAccessToken());

    private addServiceOrder = (serviceToOrder: ServiceToOrder) => {
        logger.info('adding service to cart', serviceToOrder);
        // Register GA event.
        const eventParam = getAddOrRemoveGaEventPayload(serviceToOrder);
        GAApi().event('add_to_cart', eventParam);
        const newServicesToOrder = [...this.servicesToOrder, serviceToOrder];
        this.manager.set({
            cartId: this.cartId,
            servicesToOrder: newServicesToOrder
        }, getMobxStores().userStore.isLoggedIn);
        this.syncDiscountCodes();
    };

    private removeServiceToOrder = (serviceToOrder: ServiceToOrder) => {
        logger.info('removing service to cart', serviceToOrder);
        // Register GA event.
        const eventParam = getAddOrRemoveGaEventPayload(serviceToOrder);
        GAApi().event('remove_from_cart', eventParam);
        const newServicesToOrder = this.servicesToOrder.filter(service => service.uuid !== serviceToOrder.uuid);
        this.manager.set({
            cartId: this.cartId,
            servicesToOrder: newServicesToOrder
        }, getMobxStores().userStore.isLoggedIn);
        this.syncDiscountCodes();

        // Update PayPalItems - Just used on the checkout store! do not remove!
        const { paymentStore } = getMobxStores().userStore;
        if (paymentStore.dropinInstance) {
            const subTotal = this.calculateSubTotal();
            const paypalPaymentAmount = paymentStore.getPayPalTotalAmount(subTotal);
            const items = paymentStore.getPayPalLineItems(this.servicesToOrder, subTotal);
            paymentStore.dropinInstance.updateConfiguration('paypal', 'amount', paypalPaymentAmount);
            paymentStore.dropinInstance.updateConfiguration('paypal', 'LineItems', items);
        }
    };

    public calculateSubTotal(): number {
        if (!this.servicesToOrder) {
            return 0;
        }

        let subTotal: number = 0;
        this.servicesToOrder.forEach((cartItem: ServiceToOrder) => {
            subTotal = subTotal + (cartItem.fee || 0);
        });

        return subTotal;
    }

    public calculateDiscountTotal(): number {
        if (!this.servicesToOrder) {
            return 0;
        }

        let total: number = 0;
        this.servicesToOrder.forEach((cartItem: ServiceToOrder) => {
            total = total + (cartItem.discountFee || 0);
        });

        return total;
    }

    public get hasItemsInCart() {
        return !!this.servicesToOrder.length;
    }

    public calculatePaypalFee = (subTotal: number): number => {
        return (0.024 * subTotal) + 0.3;
    }

    public serviceToOrderExists = (serviceToOrderUuid: string): boolean => {
        const exists = this.servicesToOrder.find(service => service.uuid === serviceToOrderUuid);
        if (exists === undefined) return false;
        return true;
    };

    /**
     * Add or remove service to the cart
     * Trigger the respective GA events
     */
    public toggleServiceInCart = (
        selectedService: Service,
        serviceType: ServiceTypes,
        serviceIdentifier: string,
        data: IServiceOrderRequest,
        serviceResults: OperationState<ListServicesResult> | null = null
    ) => {
        if (!selectedService) return null;

        // Allow set the recommendations based on the available services for the selected entity.
        const availableServiceIdsForEntity = serviceResults?.state?.data?.filter(s => s.isAvailable).map(availableService => availableService['id']) ?? [];
        // Only set if not present already
        if ((data.availableServiceIds?.length ?? 0) == 0) data = { ...data, availableServiceIds: availableServiceIdsForEntity };

        const serviceToOrder = createServiceToOrder(selectedService, serviceType, serviceIdentifier, data);

        // If service to order creation not valid, serviceToOrder will be null.
        if (!serviceToOrder) return;

        if (this.serviceToOrderExists(serviceToOrder.uuid)) this.removeServiceToOrder(serviceToOrder);
        else this.addServiceOrder(serviceToOrder);
    };

    // Sync cartId and servicesToOrder state
    private _setCart = (cart: CartDto) => {
        runInAction(() => {
            this.cartId = cart.cartId;
            this.servicesToOrder = cart.servicesToOrder;
            this.containsVicServiceToOrder = this.servicesToOrder.some(cartItem => (
                cartItem.serviceType === ServiceTypes.Property
                && cartItem.stateCode === 'VIC'
            ));
        });
    }

    // Check if service is already in the cart
    public isServiceInCart = (selectedService: Service): boolean => {
        const serviceUuid = selectedService.uuid;

        // Some will return true once the service is found in cart, else false will be returned.
        return this.servicesToOrder.some(service => service.uuid === serviceUuid);
    };

    // Add discount code.
    // Returns true if code was applied, returns false if there was an issue.
    public addDiscountCodeToActiveDiscountCodes = (discountCode: DiscountCode): boolean => {
        this.activeDiscountCodes.set(discountCode.code, discountCode);
        this.setActiveDiscountCodes(new Map(this.activeDiscountCodes));
        if (!this.servicesToOrder.length) return false;
        // Apply the code to the services to order, if valid array returned,
        // update them.
        const newServicesToOrder = applyDiscountCodeToCartItems(discountCode, this.servicesToOrder);
        // Warn the user if they used a code but it is not applicable...
        // NOTE: MUI!
        if (!newServicesToOrder || newServicesToOrder.every(cartItem => !cartItem.discountFee)) {
            return false;
        }
        this.manager.set({
            cartId: this.cartId,
            servicesToOrder: newServicesToOrder
        }, getMobxStores().userStore.isLoggedIn);
        return true;
    };

    // Remove discount code.
    // Remove all related discounts.
    public removeDiscountCodeFromActiveDiscountCodes = (code: string) => {
        this.activeDiscountCodes.delete(code);
        this.setActiveDiscountCodes(new Map(this.activeDiscountCodes));
        // Clear allocated discounts pertaining to that discount code.
        const newServicesToOrder: ServiceToOrder[] = [];
        this.servicesToOrder.forEach((sto, i) => {
            if (sto.discountCode === code) {
                newServicesToOrder.push({
                    ...sto,
                    discountCode: undefined,
                    discountId: undefined,
                    discountFee: undefined
                });
            } else {
                newServicesToOrder.push(sto);
            }
        });
        this.manager.set({
            cartId: this.cartId,
            servicesToOrder: newServicesToOrder
        }, getMobxStores().userStore.isLoggedIn);
    };

    // Clear all discount codes.
    // Clear all allocated discounts.
    public clearActiveDiscounts = () => {
        this.setActiveDiscountCodes(new Map<string, DiscountCode>());
        const newServicesToOrder = this.servicesToOrder.map(sto => ({
            ...sto,
            discountCode: undefined,
            discountId: undefined,
            discountFee: undefined
        }));
        this.manager.set({
            cartId: this.cartId,
            servicesToOrder: newServicesToOrder
        }, getMobxStores().userStore.isLoggedIn);
    };

    public setActiveDiscountCodes(mp: Map<string, DiscountCode>) {
        this.activeDiscountCodes = mp;
        // Persist in local storage.
        discountCodePersister.set(this.activeDiscountCodes);
    }

    // Sync the active discount code with the cart items.
    private syncDiscountCodes() {
        // Consider any active discount code...
        const activeCode = this.activeDiscountCodes.values().next().value ? this.activeDiscountCodes.values().next().value : null;
        if (activeCode && this.servicesToOrder.length) {
            this.removeDiscountCodeFromActiveDiscountCodes(activeCode.code);
            this.addDiscountCodeToActiveDiscountCodes(activeCode);
        }
    }
}
