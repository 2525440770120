import { ComponentsOverrides, ComponentsProps } from '@mui/material';
import { Theme } from '@mui/system';
import { muiColoursTheme } from './muiColoursTheme';

export const muiOutlinedInputTheme: {
    defaultProps?: ComponentsProps['MuiOutlinedInput'];
    styleOverrides?: ComponentsOverrides<Theme>['MuiOutlinedInput']
} = {
    styleOverrides: {
        root: () => ({
              '& fieldset': {
                borderColor: muiColoursTheme.ITG_BLACK,
              },
              '&:hover fieldset': {
                  borderColor: muiColoursTheme.ITG_BLUE
              },
              '&.Mui-focused fieldset': {
                borderColor: muiColoursTheme.ITG_BLUE,
              },
        })
    }
};