import { logger } from '@infotrack/infotrackgo.web.core/framework';
import { CancelToken } from 'axios';
import { AccountsApi } from '~/api/accounts/accounts';
import { UserStore } from '~/stores/user/userStore';


// Executes a getMe and keeps the user store in lock-step with the result.
export const getMeAndSyncWithStore = async (userStore: UserStore, cancellationToken?: CancelToken): Promise<void> => {
    try {
        // Get the user info.
        const userInfo = await AccountsApi.getMe(cancellationToken);
        if (userInfo) userStore.updateUserInformation(userInfo);
        // Update terms...
        userStore.checkoutStore.setTermsAccepted(!!userInfo.termsAcceptedAt);
    } catch (e: any) {
        logger.error('Failed to getMe');
    }
};