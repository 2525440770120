import { loadInstanceVariables } from '@infotrack/infotrackgo.web.core/framework/utils/loadInstanceVariables';
import { UserRole } from '@infotrack/infotrackgo.web.core/enums/UserRole';

export interface IAppUser {
    userLoginId: number;
    name: string;
    email: string;
    mobileNumber: string;
    isVerified: boolean;
    userRole: UserRole;
}

export class AppUser implements IAppUser {    
    userLoginId: number;
    name: string = '';
    email: string = '';
    mobileNumber: string = '';
    isVerified: boolean;
    userRole: UserRole;
    termsAcceptedAt: string | null = null;
    createdAt: string | null = null;

    constructor(opts?: Partial<AppUser>) {
        loadInstanceVariables(this, opts);
    }

    public getFirstName(): string {
        return this.name?.split(' ')[0] ?? '';
    }

    public getLastName(): string {
        const surnames = this.name?.split(' ').slice(1);
        return surnames.join(', ') ?? '';
    }

    public generateUniqueKey(): string {
        const key = `${this.name}-${this.mobileNumber}-${this.email}`;
        return key;
    }
}
