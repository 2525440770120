import { ConnectTokenResponse } from '@infotrack/infotrackgo.web.core/models';
import { UserProfile } from 'oidc-client-ts';

// Token Response from AutoLogin endpoint.
// This help us desconstruct the token and re-build the one used by the OIDC - new oidcUser (UserSettings) to keep things smooth.
// This is because AutoLogin(backend) uses the password flow and here we use the authorization code flow.
export class AppUserTokenResponse implements ConnectTokenResponse {
  access_token: string = '';
  refresh_token: string = '';
  expires_in: number = 0;
  token_type: string = '';
  scope: string = '';
  appUserProfile: AppUserProfile;
  oidcUserProfile: UserProfile;
}

export const GetAppUserProfile = (token: string): AppUserProfile => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  let userProfile: AppUserProfile = JSON.parse(window.atob(base64));
  return userProfile;
};

export const GetOidcUserProfile = (token: string): UserProfile => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  let oidcProfile: UserProfile = JSON.parse(window.atob(base64));
  return oidcProfile;
};

// InfoTrack claims when we want to retrive the profile scope.
export interface AppUserProfile extends UserProfile {
  'inf:username': string;
  'inf:email': string;
  'inf:loginid': number;
  'inf:partyname': string;
  'inf:retailerid': string;
  'inf:subsystem': string;
  'client_id': string;
}