import { PageResponse, IAddress, Address } from '@infotrack/infotrackgo.web.core/models';
import { CancelToken } from 'axios';
import { Api } from '~/api/api';

// List the addresses in a user's "Watchlist".
export const listAddresses = (page: number = 1, cancelToken: CancelToken) => {
    // Send page number - 1 because the list endpont works on pages starting from 0.
    return Api.get<PageResponse<IAddress>>(`accounts/users/addresses?page=${page - 1}`, { cancelToken })
        .then((data) => ({
            ...data,
            results: data.results.map(add => new Address(add))
        } as PageResponse<Address>));
};
