import { makeAutoObservable } from 'mobx';
import { WindowDimensions } from '~/stores/util/windowDimensions';

// Will allow common access to dimensions to normal react components.
// This way we don't need to guess height/width to set but can be exact and
// not cause any uneccessary overflows.
export class DimensionsStore {

    public windowDimensions: WindowDimensions = {};

    constructor() {
        makeAutoObservable(this);
    }

    public setWindowDimensions = (dimensions: WindowDimensions) => {
        this.windowDimensions = { ...dimensions };
    };

    public updateWindowDimensions = (dimensions: Partial<WindowDimensions>) => {
        if (dimensions && typeof dimensions === 'object') {
            this.windowDimensions = {
                ...this.windowDimensions,
                ...dimensions
            };
        }
    };
}
