import { PageProps } from '~/models/App/PageProps';
import { TrackingInformation } from '~/models/App/TrackingInformation';

// Get google tracking information from url search paramaters.
export const getTrackingInformation = (ctx: PageProps): TrackingInformation => {
    if (!ctx.query) {
      return {};
    }

    // Fun Fact: 'Referer' was spelt wrong on the HTTP release and has never been changed since. https://en.wikipedia.org/wiki/HTTP_referer
    let referrer;
    if (!process.browser) {
      referrer = ctx.req.headers['referer'];
    } else {
      referrer = document.referrer;
    }

    // Google analytics tracking information
    // -- Paid Ads --
    const queryGCLID = ctx.query['gclid'];
    const queryDCLID = ctx.query['dclid'];
    const queryGCSRC = ctx.query['gcsrc'];
    // The debug flag will tell any consequent calls to third parties
    // that we are in a debugging/testing context.
    const debug = +ctx.query['debug'];

    // --- Campaigns --
    const queryCampaignName = ctx.query['utm_campaign'];
    const queryCampaignSource = ctx.query['utm_source'];
    const queryCampaignMedium = ctx.query['utm_medium'];

    return new TrackingInformation({
        campaignMedium: queryCampaignMedium,
        campaignSource: queryCampaignSource,
        campaignName: queryCampaignName,
        gclid: queryGCLID,
        dclid: queryDCLID,
        gclsrc: queryGCSRC,
        referrer: referrer,
        debug: debug
    });
};
