import { createTheme, responsiveFontSizes, ThemeOptions } from '@mui/material/styles';
import { muiButtonTheme } from './muiButtonTheme';
import { muiCheckboxTheme } from './muiCheckboxTheme';
import { muiMenuItemTheme } from './muiMenuItemTheme';
import { muiOutlinedInputTheme } from './muiOutlinedInputTheme';
import { muiPaletteTheme } from './muiPaletteTheme';
import { muiTypographyComponent, muiTypographyTheme } from './muiTypographyTheme';

const themeOptions: ThemeOptions = {
    palette: muiPaletteTheme,
    components: {
      MuiButton: muiButtonTheme,
      MuiOutlinedInput: muiOutlinedInputTheme,
      MuiMenuItem: muiMenuItemTheme,
      MuiTypography: muiTypographyComponent,
      MuiCheckbox: muiCheckboxTheme
    },
    typography: muiTypographyTheme,
    shape: {
      borderRadius: 4,
    },
  };

// Create a theme instance.
let theme = createTheme(themeOptions);

// Automates our font size responsiveness according to the screen size
// See more: https://mui.com/material-ui/customization/theming/#responsivefontsizes-theme-options-theme
theme = responsiveFontSizes(theme);

export default theme;
