import { makeAutoObservable, runInAction } from 'mobx';
import { SignUpOptions } from '~/components/forms/UserSignUpForm/types/SignUpOptions';
import { Constants } from '@infotrack/infotrackgo.web.core/framework/constants/constants';
import { getLocalStorage } from '@infotrack/infotrackgo.web.core/framework/utils/getLocalStorage';
import { iterableToArray } from '@infotrack/infotrackgo.web.core/framework/utils/iterableToArray';
import { localStorageManager } from '@infotrack/infotrackgo.web.core/framework/utils/localStorageFormManager';
import { Payment } from '~/models/Payment/Braintree/BraintreeState';
import { getMobxStores } from '~/pages/_app';
import { AppStore } from '../AppStore';
import { resetSignupModeOnLocalStorage } from '~/components/forms/UserSignUpForm/types/SignupMode';
import { CheckoutWorkflowStepTitles } from './CheckoutWorkflowStepTitles';
import { CheckoutStep } from './CheckoutStep';
import { logger } from '@infotrack/infotrackgo.web.core/framework';
import { HostedFieldsTokenizePayload } from '~/models/Payment/Braintree/HostedFields/HostedFieldsTokenizePayload';
import { ThreeDSecureVerifyPayload } from '~/models/Payment/Braintree/ThreeDSecure/ThreeDSecureVerifyPayload';
import { RequestPaymentPayload } from '~/models/Payment/RequestPaymentPayload';

declare const braintree;

export class CheckoutStore {
    // Defines the necessary selected option by the user to be able to proceed into the next step.
    public orderStepSelectedOption: SignUpOptions | null = localStorageManager<SignUpOptions>(Constants.LOCAL_STORAGE.ORDER_STEP_SELECTED_OPTION_KEY)?.get();

    // The current braintree state, is updated by PaymentWidgetComponents.CustomBraintreePaymentForm
    public braintreeState: Payment.Braintree.BraintreeState | undefined = undefined;

    // Field is private so that we can intercept the getter logic to consider if termsAcceptedAt is on the user.
    private termsAccepted = false;

    // Flag for showing the loading indicator of the complete order.
    public completeOrderIsLoading = false;

    // Flag for showing loading indicator inside complete order button.
    public threeDSIsLoading = false;

    public braintreeClientInstance: any = null;

    public braintreeClientToken: string | null = null;

    public static StepsRegistry: Map<CheckoutWorkflowStepTitles, CheckoutStep> = new Map<CheckoutWorkflowStepTitles, CheckoutStep>([
        [
            CheckoutWorkflowStepTitles.Order,
            new CheckoutStep({
                name: CheckoutWorkflowStepTitles.Order,
                checkFunc: (appStore: AppStore): boolean => {
                    return !!appStore.userStore.checkoutStore.orderStepSelectedOption;
                }
            }),
        ],
        [
            CheckoutWorkflowStepTitles.ContactDetails,
            new CheckoutStep({
                name: CheckoutWorkflowStepTitles.ContactDetails,
                checkFunc: (appStore: AppStore) => {
                    if (appStore.userStore.isLoggedIn && appStore.userStore.userInformation?.isVerified) return true;

                    switch (appStore.userStore.checkoutStore.orderStepSelectedOption) {
                        case SignUpOptions.Customer:
                            // Note: after the form is completed and the user created, this info should be held on userInformation
                            return !!appStore.userStore.userInformation?.isVerified;
                        case SignUpOptions.Guest:
                            return !!appStore.formsStore.signUpFormStore?.isValid;
                        // for anything else, the user is logged and if so, it must be verified
                        default:
                            return Boolean(appStore.userStore.isLoggedIn && appStore.userStore.userInformation?.isVerified);
                    }
                }
            })
        ],
        [
            CheckoutWorkflowStepTitles.Payment,
            new CheckoutStep({
                name: CheckoutWorkflowStepTitles.Payment,
                checkFunc: (appStore: AppStore) => {
                    const cStore = appStore.userStore.checkoutStore;
                    // Check validity of the braintree state and that the user has checked the terms...
                    return !!(cStore.braintreeState?.isValid && cStore.getTermsAccepted());
                }
            })
        ]
    ]);

    constructor() {
        makeAutoObservable(this);
    }

    public getTermsAccepted = () => {
        return !!(
            this.termsAccepted ||
            getMobxStores().userStore.userInformation?.termsAcceptedAt
        );
    }

    public setTermsAccepted = (checked: boolean) => {
        this.termsAccepted = checked;
    }

    public setOrderStepSelectedOption = (selectedOption: SignUpOptions | null) => {
        this.orderStepSelectedOption = selectedOption;
        localStorageManager<SignUpOptions>(Constants.LOCAL_STORAGE.ORDER_STEP_SELECTED_OPTION_KEY)?.set(selectedOption);
    }

    public getBraintreeState(): Payment.Braintree.BraintreeState | undefined {
        return this.braintreeState;
    }

    public setBraintreeState(state?: Payment.Braintree.BraintreeState | undefined) {
        this.braintreeState = new Payment.Braintree.BraintreeState(state);
    }

    public setCompleteOrderIsLoading(loading: boolean) {
        this.completeOrderIsLoading = loading;
    }

    public setThreeDSIsLoading(loading: boolean) {
        this.threeDSIsLoading = loading;
    }

    public setBraintreeClientInstance(instance: any) {
        this.braintreeClientInstance = instance;
    }

    public setBraintreeClientToken(token: string) {
        this.braintreeClientToken = token;
    }

    /**
     * Using the current breaintree state, invoke the tokeniser and get the braintree nonce.
     */
    public requestBraintreeTokenization(): Promise<HostedFieldsTokenizePayload | RequestPaymentPayload> {
        return new Promise((resolve, reject) => {
            if (typeof this.braintreeState?.tokeniser === 'undefined') return resolve;
            return this.braintreeState.tokeniser()
                .then(resolve)
                .catch(reject);
        });
    }

    /**
     * Using the current breaintree state, invoke the 3DSecure verifyCard and get the threeDSecureAuthorisationId
     */
    public verifyCardWithBraintree3DSecure(hostedFieldsTokenizePayload: HostedFieldsTokenizePayload): Promise<ThreeDSecureVerifyPayload | undefined> {
        return new Promise((resolve, reject) => {
            if (typeof this.braintreeState?.verifyCardWithThreeDSecure === 'undefined') return resolve(undefined);
            return this.braintreeState.verifyCardWithThreeDSecure(hostedFieldsTokenizePayload)
                .then(resolve)
                .catch(reject);
        });
    }

    /**
     * Using the braintree client instance get the device data to send to braintree for
     * greater fraud resilience.
     */
    public requestBraintreeDeviceData(): Promise<string> {
        return new Promise((resolve, reject) => {
            if (!this.braintreeClientInstance) return reject('Missing braintree client instance');
            braintree.dataCollector.create({
                    client: this.braintreeClientInstance
                },  (err, dataCollectorInstance) => {
                    if (err) return reject(err);
                    return resolve(dataCollectorInstance.deviceData);
                }
            );
        });
    }

    // A getter function that returns true if all checkout steps have been completed.
    public checkoutComplete(store: AppStore): boolean {
        logger.info('Checking all steps completion status', {
            ...store.userStore?.userInformation,
            mobileNumber: '',
            email: '',
            orderStepSelectedOption: store.userStore?.checkoutStore?.orderStepSelectedOption,
            isVerified: store.userStore?.userInformation?.isVerified,
            isSignUpFormStoreValid: store.formsStore?.signUpFormStore?.isValid,
            isLoggedIn: store.userStore.isLoggedIn,
            braintreeStateIsValid: store.userStore?.checkoutStore?.braintreeState?.isValid,
            termsAccepted: store.userStore?.checkoutStore?.getTermsAccepted()
        });

        return iterableToArray(CheckoutStore.StepsRegistry.values()).every(step => {
            const isComplete = step.isComplete(store);
            logger.info(`${step.name} - isComplete: ${isComplete}`);

            return isComplete;
        });
    }

    public resetPaymentInfo() {
        runInAction(() => {
            this.setBraintreeState(undefined);
            this.setCompleteOrderIsLoading(false);
        });
    }

    public resetContactDetailtsSignUpMode = () => {
        resetSignupModeOnLocalStorage();
    }

    // Resets the flow.
    public reset(): void {
        this.setOrderStepSelectedOption(null);
        this.resetContactDetailtsSignUpMode();
        this.resetPaymentInfo();
        getLocalStorage()?.setItem(Constants.LOCAL_STORAGE.CURRENT_CHECKOUT_STEP, '0');
    }
}
